export const fetchData = (item) =>
  JSON.parse(localStorage.getItem(item));

export const createData = (item, itemlist) => {
  localStorage.setItem(item, JSON.stringify(itemlist));
};

export const removeData = (item) => {
    localStorage.removeItem(item);
};

export const updateData = (item, indexToUpdate, value) => {
    const data = fetchData(item)
  
    const newData = data.map((url, idx) => {
        if (idx === indexToUpdate) {
            return value;
        }
        return url;
    });
  localStorage.setItem('url', JSON.stringify(newData));
};