

import { toast } from "react-toastify";


export const copyToClipBoard = copyMe => {
    try {
        navigator.clipboard.writeText(copyMe);
        toast.success("Copied", {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         });
    } 
    catch (err) {
     toast.error("Failed to copy!", {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         });
        
    }
 };