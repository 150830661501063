import React, {useContext} from "react";

// import {images} from '../../../../assests/index'

import { DataContext } from "../../pages/context/DataContext";

import UserDropdown from "../../components/Dropdowns/UserDropdown";
// import { images } from "../../assets/images";
import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown";

export default function AdminNavbar() {

  const data = useContext(DataContext)
  return (
    <>
      {/* Navbar */}
      <nav className="relative bg-white w-full border-l-2 border-b-2 border-slate-200 md:flex-row md:flex-nowrap md:justify-start flex items-center py-2">
        <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */} 
          <a
            className=" text-primary-purple font-gilroy-bold text-lg font-normal uppercase inline-block"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            {data.currentPage}
          </a>
          {/* Form */}

          <div className="flex justify-center items-center">
          

          <div className="flex justify-center items-center">
            
            
            <ul className="flex-col md:flex-row list-none items-center mr-2 hidden md:flex">
              <NotificationDropdown/>
              <UserDropdown firstname={data.firstname}  image={data.image}/>
            </ul>
          </div>  

          </div>


        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
