export const images = {
    logo: [require('./logo.png') , "logo"],
    loginBackground: ['https://myadvantage.africa/v2/wp-content/themes/levelmarketing/assets/media/background-partern.png','background'],
    success: [require('./payment-image.png'), "payment"],
    notificationOn: [require('./notification.png'), "Notification available"],
    notificationOff: [require('./notification-frame.png'), "Notification not available"],
    vectorTwo: [require('./Vector (2).png'), "Vector"],
    vectorOne: [require('./Vector (1).png'), "Vector"],
    sFour:[require('./s4.png'), "Vector"],
    sSix:[require('./s6.png'), "Vector"],
    sSeven:[require('./s7.png'), "Vector"],
    sEight:[require('./s8.png'), "Vector"],
    whatsapp:[require('./whp.png'), "whatsapp"],
    twitter:[require('./twitter.png'), "twitter"],
    fb:[require('./fb.png'), "facebook"],
    ellipseOne:[require('./Ellipse 6.png'), "ellipse"],
    ellipseTwo:[require('./Ellipse 7.png'), "ellipse"],
    copy:[require('./copy.png'), "copy"],
    colorOne:[require('./color-1.png'), "color-green"],
    colorTwo:[require('./color-2.png'), "color-red"],
    users:[require('./users.png'), "users"],
    mOne:[require('./m1.png'), "wallet"],
    eye:[require('./eye.png'), "eye"],
    profile:[require('./profile.png'), "profile"],
    boxIcon:[require('./box-icon.png'), "box"],
    topArrow:[require('./top-arrow.png'), "top arrow"],
    downArrow:[require('./down-arrow.png'), "down arrow"],
    upArrow:[require('./up-arrow.png'), "up arrow"],
    hand: [require('./hand.png'), "hand"],
    whatsappBackground: [require('./whatsapp-background.png'), "whatsapp"],
    fbBackground: [require('./fb-background.png'), 'facebook'],
    emptyOne: [require('./empty-list-1.png'), 'empty list'],
    emptyTwo: [require('./empty-list-2.png'), 'empty list'],
    doubleCircle: [require('./double-circle-top.png'), "double circle"],
    forwardArrow: [require('./forward-arrow.png'), "forward arrow"],
    lock: [require('./Lock.png'), "Lock"],
    webBack: [require('./website-back-arrow.png'), "website-back-arrow"],
    greenInfo: [require('./green-info.png'), "green-info"],
    profileBlank: [require('./profile-blank.jpg'), 'profile blank'],
    referralImage: [require('./referral.png'), "referral"],
    bankTopUp: [require('./bank-top-up.png'), "bank top up"],
    cardTopUp: [require('./card-top-up.png'), 'card top up'],
    withdraw: [require('./withdraw.png'), 'withdraw'],
    topUp: [require('./top-up.png'), 'top-up'],
    rightIcon: [require('./right-icon.png'), 'right-icon'],
    rightBigCircle:[require('./Ellipse 49.png'), 'rightBigCircle'],
    walletBackground:[require('./wallet-background.png'), 'wallet background'],
    checkGif:[require('./check-gif.png'), 'Check Gif'],
    powerButton:[require('./Power-button.png'), 'Power Button'],
    successMarker:[require('./success-marker.png'), 'Success Marker']

}