import React, { useCallback, useState } from 'react'

import * as Icons from "react-icons/io";

import '../auth.css'

// import {useNavigate} from 'react-router-dom';

import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Backdrop from '../../UI_Elements/Backdrop/Backdrop';
import DropboxUpload from './DropboxUpload';
import Input from '../../UI_Elements/Input/Input';
import { VALIDATOR_REQUIRE } from '../../util/validators';
import { useForm } from '../../hooks/form-hook';
import { useHttpClient } from '../../hooks/http-hook';

function TransferModalOverlay(props) {
    // const history = useNavigate();

    const [done, setDone] = useState(false)

    const [uploadImages, setUploadImages] = useState([])

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
      inputHandler] = useForm({

      bank_name: {
          value: '',
          isValid: false
      },
      bank_account: {
          value: '',
          isValid: false
      }
  }, false);

    const imagesHandler = useCallback( (files) => {
        
      setUploadImages(files)
      
  }, [])

  const submitHandler = async(event) =>{
    event.preventDefault();
        

    try {

        const data = JSON.stringify({
            firstname: props.firstname,
            lastname: props.lastname,
            image: uploadImages[0],
            ref: props.reference,
            bank_account: formState.inputs.bank_account.value,
            bank_name: formState.inputs.bank_name.value
        })

        const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/manualpayments`, 'POST', data, {'Content-Type': 'application/json',Authorization: 'Bearer '+props.token});

        if(responseData){
          setDone(!done)
        }
        

    } catch (err) {    
        console.log(error)
    }
  }

    const content = (
        <div className='p-8 md:p-24 modal'>
            <div className='flex flex-col justify-center items-center '>
              <DropboxUpload colSpan title="Upload transfer receipt" accept="image/*" browse="(jpg, png, jpeg)" onFiles={imagesHandler} className="w-5/6 mb-4"/>

              <Input 
                    type="text"
                    placeholder='e.g Guarantee Trust Bank / GTB'
                    label="Bank Name"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="bank_name"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid bank name"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    divClassName={`mb-4 `}
                    className={`appearance-none ${formState.inputs.bank_name.value && "bg-light-purple"} rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-link-color rounded-t-md focus:outline-none focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple focus:z-10 sm:text-sm`}/>


                <Input
                    type="text"
                    placeholder='Bank account number'
                    label="Bank Account Number"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="bank_account"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid bank account"
                    onInput={inputHandler} 
                    containerClassName={`relative`}
                    
                    className={`appearance-none rounded-none ${formState.inputs.bank_account.value && "bg-light-purple"} relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-link-color rounded-t-md focus:outline-none focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple focus:z-10 sm:text-sm`}
                    />

              <p className='my-8 text-center'>You’ll receive an email notification to sign in to your dashboard once your payment is confirmed.</p>

              <button className='bg-primary-purple px-4 py-2 text-white rounded mb-6' onClick={submitHandler}>
                {isLoading ? "Processing" : "Verify your payment"}
              </button>
                {done && <p className='text-primary-purple text-lg mt-3 flex items-center justify-start'>Verification in process check back within 24 hours</p>}
                <p className='text-primary-purple text-sm mt-8 flex items-center justify-center'>Contact Finance@myAdvantage.com for inquiries</p>
            </div>
            <div className='absolute top-4 right-4'>
            <button onClick={props.onCancel} >
                <Icons.IoIosClose className='text-lg text-white bg-primary-purple rounded pointer' />
            </button>
            </div>
        </div>
    )

    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const TransferModal = props => {
    return (
      <React.Fragment>
        {props.show && <Backdrop onClick={props.onCancel} />}
        <CSSTransition
          in={props.show}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames="modal"
        >
          <TransferModalOverlay {...props} />
        </CSSTransition>
      </React.Fragment>
    );
  };


export default TransferModal
