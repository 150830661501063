import React, { useContext, useEffect, useState } from 'react'

import * as Icons from "react-icons/io";
import ReactDOM from 'react-dom';


import { CSSTransition } from 'react-transition-group';
import { ToastContainer } from 'react-toastify'

import { DataContext } from '../../context/DataContext';


import Number from '../../../UI_Elements/Input/Number';
import Backdrop from '../../../UI_Elements/Backdrop/Backdrop';
// import { images } from '../../../assets/images';
import Flutterwave from './components/Flutterwave';
import { useHttpClient } from '../../../hooks/less-http-hook';
import { AuthContext } from '../../../context/auth-context';
import Loader from '../../../UI_Elements/loader/Loader';


function CardTopUpModalOverlay(props) {
    const user_data  = useContext(DataContext)
    const auth = useContext(AuthContext);

    const [mainPage, setMainPage] = useState(true)

    const [flutterShow, setFlutterShow] = useState(false)

    const [amountInput, setAmountInput] = useState(null)

    const {isLoading, error, sendRequest} = useHttpClient();

    const [txRef, setTxRef] = useState(null)
    
    const numberInput = (value) => {
      setAmountInput(value)
    }

    useEffect(() => {
      setMainPage(true)
      setFlutterShow(false)
    }, [])

    let config ={
      public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
      tx_ref: "",
      amount: amountInput,
      currency: 'NGN',
      payment_options: 'card,mobilemoney,ussd',
      customer: {
        email: user_data.email,
        phonenumber: user_data.phonenumber,
        name: `${user_data.firstname} ${user_data.lastname}`,
      },
      customizations: {
        title: `Myadvantage account deposit`,
        description: `myadvantange top up`,
      },
    };
    

    const amountHandler = async() => {

      
        try {
            
            const data = JSON.stringify({amount: amountInput})
    
            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/wallet/fund`, 'POST', data, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            });

    
            if(responseData){
              
              setTxRef(responseData.transactions.reference)
              setFlutterShow(!flutterShow)
              setMainPage(!mainPage)
            }
    
        } catch (err) {
            
            console.log(error)
        }

      
  }

    const onCancelFlutterwave = () => {
      setFlutterShow(!flutterShow)
      setMainPage(!mainPage)
    }

    const content = (
        <div className='p-3 py-8 md:p-8 lg:pb-16 modal'>
          <ToastContainer colored/>
            {flutterShow && <Flutterwave show={flutterShow} onCancel={onCancelFlutterwave} config={config} txRef={txRef}/>}
           {mainPage && (
            <>
              <h3 className='text-link-color text-lg font-gilroy-semibold'>Add amount</h3>

              <div className='flex justify-center items-center flex-col mt-10 '>
                <Number className="font-gilroy-bold text-center w-full text-link-color text-2xl lg:text-2xl" onInput={numberInput}/>
              </div>

              {isLoading && <Loader/>}

              <div className='w-full mt-16 flex justify-center'>
              <button className='bg-primary-purple text-white py-3 w-3/5 rounded-md ' onClick={amountHandler}>
                  Continue
              </button>
              </div>
            </>
           )}
            
            <div className='absolute top-4 right-4'>
              <button onClick={props.onCancel} className="rounded-full cursor-pointer p-2 bg-border-color">
                  <Icons.IoIosClose className='text-lg text-primary-black pointer' />
              </button>
            </div>
        </div>
    )

    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const CardTopUpModal = props => {
    return (
      <React.Fragment>
        {props.show && <Backdrop onClick={props.onCancel} />}
        <CSSTransition
          in={props.show}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames="modal"
        >
          <CardTopUpModalOverlay {...props} />
        </CSSTransition>
      </React.Fragment>
    );
  };


export default CardTopUpModal
