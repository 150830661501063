import React from 'react'



function EmptyTransactions(props) {
  return (
    <div className={`flex flex-col justify-center items-center mt-6 ${props.className}`}>
        <img src={props.src} alt={props.alt} className="object-cover w w-1/3"/>
        <h3 className='mt-4 text-link-border font-gilroy-medium text-sm xl:text-lg'>{props.text}</h3>
    </div>
  )
}

export default EmptyTransactions