import React, { useContext, useState } from 'react'

// import * as Icons from "react-icons/io";



import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Backdrop from '../../../UI_Elements/Backdrop/Backdrop';

import "./kyc.css"
import { images } from '../../../assets/images';
import Bvn from './components/Bvn';
import Identity from './components/Identity';
import Kyc from './components/Kyc';
import { ToastContainer } from 'react-toastify'
import { DataContext } from '../../context/DataContext';


function KycModalOverlay() {

    const data = useContext(DataContext)
    

    const [bvn, setBvn] = useState({
      value: false,
      done: false, 
      lock:false,
      edit: true
    })

    const [identity, setIdentity] = useState({
      value: false,
      done: false,
      lock: true,
      edit: false
    })
    const [kyc, setKyc] = useState({
      value: false,
      done: false,
      lock:true,
      edit: false
    })
    

    const [mainPage, setMainPage] = useState(true)




  const bvnChangeHandler = (done) =>{
      if(done === "done"){
        setBvn({
          value: false,
          done: true,
          lock:false,
          edit:false

        })
        setIdentity({
          value: false,
          done: false,
          lock:false,
          edit:true

        })
        setMainPage(!mainPage)
      }
      else{
        setBvn({
          value: false,
          done: false,
          lock:false,
          edit:true

        })
        setMainPage(!mainPage)
      }
  } 

  const identityChangeHandler = (done) =>{
    if(done === "done"){
      setIdentity({
        value: false,
        done: true,
        lock:false,
        edit:false

      })
      setKyc({
        value: false,
        done: false,
        lock:false,
        edit:true

      })
      setMainPage(!mainPage)
    }
    else{
      setIdentity({
        value: false,
        done: false,
        lock:false,
        edit:true

      })
      setMainPage(!mainPage)
    }
} 
const kycChangeHandler = (done) =>{
  if(done === "done"){
    setKyc({
      value: false,
      done: false,
      lock:false,
      edit:false

    })
    setMainPage(!mainPage)
    window.location.reload(false);

  }
  else{
    setIdentity({
      value: false,
      done: false,
      lock:false,
      edit:false

    })

    setKyc({
      value: false,
      done: false,
      lock:false,
      edit:true

    })
    setMainPage(!mainPage)
  }
} 

    const content = (
        <div className='p-3 py-8 md:p-8 kyc'>
          <ToastContainer colored/>
          {bvn.value && <Bvn  bvnChangeHandler={bvnChangeHandler}/>}
          {identity.value && <Identity identityChangeHandler={identityChangeHandler}/>}
          {kyc.value && <Kyc kycChangeHandler={kycChangeHandler}/>}
            {mainPage && (
              <div className='flex flex-col justify-center items-end overflow-y-auto'>
              
              <h1 className='text-primary-purple text-base font-gilroy-semibold mb-4'>{`Congratulations ${data.firstname}! You are now a Newbie. Kindly, complete the following information`}</h1>
                
                
              <div className="flex">
                
                <div className="p-4 bg-primary-border mr-3 rounded w-5/5 sm:w-96">
                  <p className="mb-3 text-base font-semibold text-primary-purple">NIN Verification</p>
                  <div className='flex justify-between items-center'>
                    <p className="text-link-color text-xs">
                    Confirm your NIN for accurate transactions
                    </p>
                    {bvn.edit && <button onClick={()=>{
                      setMainPage(!mainPage)
                      setBvn({
                        ...bvn,
                        value:true
                      })
                    }}>
                           <img src={images.forwardArrow[0]} alt={images.forwardArrow[1]} className="w-6 h-6"/>
                          
                    </button>}
                  </div>
                </div>

                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className={`flex items-center justify-center font-gilroy-bold w-10 h-10 border rounded-full bg-primary-border text-lg ${bvn.done ? "text-primary-purple":"text-table-gray"}`}>
                      1
                    </div>
                  </div>
                  <div className={`w-px h-full ${bvn.done ? "bg-primary-purple":"bg-table-gray"} pb-4 mt-1`}></div>
                </div>
              </div>
              <div className="flex mt-2">
                
                <div className="p-4 bg-primary-border mr-3 rounded  w-5/5 sm:w-96">
                  <p className="mb-3 text-base font-semibold text-primary-purple">Identity Verification</p>
                  <div className='flex justify-between items-center'>
                    <p className="text-link-color text-xs">
                    Upload an official government issued ID to verify your identity.
                    </p>
                    {identity.edit && <button onClick={()=>{
                      setMainPage(!mainPage)
                      setIdentity({
                        ...identity,
                        value:true
                      })
                    }}>
                        <img src={images.forwardArrow[0]} alt={images.forwardArrow[1]} className="w-6 h-6"/>
                    </button>}
                    {identity.lock && <img src={images.lock[0]} alt={images.lock[1]} className="w-6 h-6"/>}
                  </div>
                </div>

                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className={`flex items-center justify-center font-gilroy-bold w-10 h-10 border rounded-full  bg-primary-border text-lg ${identity.done ? "text-primary-purple":"text-table-gray"}`}>
                      2
                    </div>
                  </div>
                  <div className={`w-px h-full ${identity.done ? "bg-primary-purple":"bg-table-gray"} pb-4 mt-1`}></div>
                </div>
              </div>
              <div className="flex mt-2 ">
                
                <div className="p-4 bg-primary-border mr-3 rounded w-5/5 sm:w-96">
                  <p className="mb-3 text-base font-semibold text-primary-purple">KYC Information</p>

                  <div className='flex justify-between items-center'>
                    <p className="text-link-color text-xs">
                      Let us know more about you.
                    </p>
                    {kyc.edit && <button onClick={()=>{
                      setMainPage(!mainPage)
                      setKyc({
                        ...kyc,
                        value:true
                      })
                    }}>
                        <img src={images.forwardArrow[0]} alt={images.forwardArrow[1]} className="w-6 h-6"/>
                    </button>}
                    {kyc.lock && <img src={images.lock[0]} alt={images.lock[1]} className="w-6 h-6"/>}
                  </div>

                  
                </div>

                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className={`flex items-center justify-center font-gilroy-bold w-10 h-10 border rounded-full bg-primary-border text-lg ${kyc.done ? "text-primary-purple":"text-table-gray"}`}>
                      3
                    </div>
                  </div>
                  {/* <div className={`w-px h-full ${kyc.done ? "bg-primary-purple":"bg-table-gray"} pb-4 mt-1`}></div> */}
                </div>
              </div>

             


            </div>
            )}
            
        </div>
    )

    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const KycModal = props => {
    return (
      <React.Fragment>
        {props.show && <Backdrop onClick={props.onCancel} />}
        <CSSTransition
          in={props.show}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames="kyc"
        >
          <KycModalOverlay {...props} />
        </CSSTransition>
      </React.Fragment>
    );
  };


export default KycModal
