import React, { useContext, useEffect, useState } from "react";
import { createPopper } from "@popperjs/core";
import { images } from "../../assets/images";
import { useHttpClient } from "../../hooks/less-http-hook";
import { AuthContext } from "../../context/auth-context";
import Loader from "../../UI_Elements/loader/Loader";
import * as Icons from 'react-icons/bi'

const NotificationDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-end",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const auth = useContext(AuthContext); 

  const [notification, setNotification] = useState([])

  const {isLoading, error, sendRequest} = useHttpClient();


    useEffect(() => {
      const fetchPlan = async() => {
          try {
              const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/list/notification`, "GET", null,{Authorization: 'Bearer '+auth.token});
              
              setNotification(responseData.notification)

          } catch (err) {
              console.log(error)
          }
      };
      fetchPlan();
  }, [sendRequest, error, auth]);


  useEffect(() => {

    // Hide on outside click
  const hideOnClickOutside = (e) => {
    if( popoverDropdownRef.current && !popoverDropdownRef.current.contains(e.target) ) {
      setDropdownPopoverShow(false);
    }
  }
    
    document.addEventListener("click", hideOnClickOutside, true)
  }, [dropdownPopoverShow, popoverDropdownRef] )

  const notificationHandler = async() =>{
    try {
      await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/list/notification/markall`, "GET", null,{Authorization: 'Bearer '+auth.token});


    } catch (err) {
        console.log(error)
    }
  }

  return (
    <>
      <button
        className=" block py-1 px-3"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        {notification.length === 0 ? <img src={images.notificationOff[0]} alt={images.notificationOff[1]} className="mr-4 h-8 w-8"/> : <img src={images.notificationOn[0]} alt={images.notificationOn[1]} className="mr-4 h-8 w-8"/>}
      </button>
      <div
        ref={popoverDropdownRef}
        onClick={notificationHandler}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48 min max-h-56 overflow-y-scroll"
        }
      >
        {isLoading && <div className="flex justify-center"><Loader/></div>}
        {!isLoading && notification.length === 0 && 
          <p className={
            "text-sm py-2 px-2 pl-4 text-left font-normal block w-full whitespace-nowrap text-primary-purple hover:text-black"
          }>No avaiable notification</p>
        }

        {!isLoading && notification.length !== 0 && notification.map((item,index) => { return(
                <div className="flex justify-start w-full p-3 py-1 items-center text-primary-black cursor-default hover:text-white hover:bg-primary-purple" key={index}>
                  <Icons.BiRadioCircle className="text-lg "/>
                  <p
                  key={index}
                  className={
                    "text-sm py-2 px-2 pl-4 text-left font-normal block w-full whitespace-nowrap bg-transparent"
                  }
                  >
                  {item.data.message}
                </p>
                </div>
                
              )}
            )}
      </div>
    </>
  );
};

export default NotificationDropdown;
