import React, { useContext, useEffect, useState } from 'react'

import moment from 'moment'

import { copyToClipBoard } from '../../util/copyToClipboard';
import {images} from '../../assets/images'
import HOC from '../hoc/HOC'

import * as Icons from "react-icons/tb"
import * as IconEyes from "react-icons/bs";
import * as IconEye from "react-icons/md";
import * as IconSocial from "react-icons/fa"
import * as IconSocials from "react-icons/ri"

import { DataContext } from '../context/DataContext';

import TransactionTable from '../components/Tables/TransactionTable'
import DashboardTreeTable from '../components/Tables/DashboardTreeTable';
import Number from '../../UI_Elements/Input/Number';
import { useHttpClient } from '../../hooks/less-http-hook';
import { AuthContext } from '../../context/auth-context';
import Loader from '../../UI_Elements/loader/Loader';
import SubscribeModal from '../components/subscribe/SubscribeModal';

function DashboardPage() {
    const data = useContext(DataContext)
    data.updatePage("Dashboard")
    const auth = useContext(AuthContext); 

    const xNumber = "X"

    let value = 0

    const [customer, setCustomer] = useState(null)
    const [upgradeData, setUpgradeData] = useState(null)

    const [subscribe, setSubscribe] = useState(false)

    const {isLoading, error, sendRequest} = useHttpClient();

    const onMlmHandler = () =>{
        if(data.mlm){
            return
        }
        else{
            onCancelSubscribe()
        }
    }

    const onlevelhandler = (level) => {
        if (level === 1 || level === null) {
            return "starter";
        }
        if (level === 2) {
            return "Rookie";
        }
        if (level === 3) {
            return "Star";
        }

        if (level === 4) {
            return "Bronze";
        }

        if (level === 5) {
            return "Silver";
        }

        if (level === 6) {
            return "Gold";
        }

        if (level === 7) {
            return "Platinum";
        }
    }

    const onCancelSubscribe = () => {
        setSubscribe(!subscribe)
    }

    useEffect(() => { 
        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/profile`, "GET", null, {Authorization: 'Bearer '+auth.token});
    
                if(responseData){
                  setCustomer(responseData.customers)
                  const downlinersData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/downliners/next?level=${responseData.customers.customerlevel}`, "GET", null, {Authorization: 'Bearer '+auth.token});
                  if(downlinersData){
                    setUpgradeData(downlinersData)
                  }
                }
    
            } catch (err) {
                console.log(error)
            }
        };
        fetchData();
    }, [sendRequest, error, auth]); 

    const walletInput = (actualValue) =>{
        value = actualValue
        localStorage.setItem("money", value)
      }

    const [eye, setEye] = useState(true)

    return (
        <HOC>
            {subscribe && <SubscribeModal show={subscribe} onCancel={onCancelSubscribe}/>}
            <h3 className='text-lg font-gilroy-medium text-primary-black'>
                Welcome, {`${data.firstname} ${data.lastname}`}
                <span className='inline-block ml-2'><img src={images.hand[0]} alt={images.hand[1]}/></span>
            </h3>

            {isLoading  ? <Loader/> : 
                customer && (
            <>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4'>

                <div className='px-4 py-4 bg-primary-black rounded '>
                    <div className='block p-3 rounded-xl bg-side-purple w-10'>
                        <img src={images.mOne[0]} alt={images.mOne[1]} className="object-contain"/>
                    </div>

                    <div className='flex justify-start items-center mt-4  '>
                        
                            <div className='flex justify-start items-center mt-4  '>
                                {eye ? <Number className=' text-white text-2xl font-gilroy-medium ' 
                                text 
                                value={customer.balance} onInput={walletInput}/> : 
                                <h3 className=' text-white text-2xl font-gilroy-medium '>
                                    {Array(customer.balance.toString().length +1).join(xNumber)+".XX"}
                                </h3>}
                            </div>
                        
                    </div>

                    <div className='flex justify-between items-center mt-4'>
                        <h3 className='text-white text-lg font-gilroy'>Wallet Balance</h3>
                        {eye ? <IconEyes.BsEye className='text-white text-2xl font-gilroy-semibold cursor-pointer' onClick={
                                () => setEye(!eye)
                            }/> : <IconEyes.BsEyeSlash className='text-white text-2xl font-gilroy-semibold cursor-pointer' onClick={
                                () => setEye(!eye)
                            }/>}
                    </div>
                </div>

                <div className='px-4 py-4 downlines_background rounded' onClick={onMlmHandler}>
                    <div className='block p-3 rounded-xl bg-side-purple w-10'>
                        <img src={images.users[0]} alt={images.users[1]} className="object-contain"/>
                    </div>

                    <h1
                        className='mt-4 text-primary-black text-xl font-gilroy-bold flex justify-start items-center'>
                        {customer.downlinerscount}
                    </h1>

                    <h3 className='mt-4 font-gilroy text-primary-black text-lg '>Total Downlines</h3>
                </div>

                <div className='bg-white px-2 py-4 row-span-2' >

                    <div className='grid grid-cols-2'>
                        <img
                            src={data.image}
                            alt={images.profile[1]}
                            className="rounded-full mr-auto border-2 border-primary-border h-32 w-32 object-cover"/>
                        <div className='mr-auto grid gap-2 ml-1 '>
                            <h3 className='text-base font-gilroy-semibold my-auto'>{`${data.firstname} ${data.lastname}`}</h3>

                            <div>
                                <p className='text-sm font-gilroy-medium'>Rank:
                                    <span className='text-primary-black font-gilroy-bold ml-1'>
                                    {customer.customerlevel}</span>
                                </p>
                                <p className='text-sm font-gilroy-medium'>Status:
                                    <span className='text-primary-purple font-gilroy-bold ml-1'>
                                        {data.subscription ? 'Active' : 'expired'}</span>
                                </p>
                            </div>

                        </div>

                    </div>

                    <div className='mt-4'>
                        <h3 className='font-gilroy-medium text-primary-black '>Referral Code</h3>

                        <div className='flex items-center '>
                            <h1 className='text-primary-purple text-2xl font-gilroy-medium mr-8'>{data.referralCode}</h1>
                            <img src={images.copy[0]} alt={images.copy[1]} className="w-4 cursor-pointer" onClick={
                              () =>{
                                copyToClipBoard(`${data.referralCode}`)
                              }
                            }/>
                        </div>
                    </div>

                    <div className='flex justify-between items-end mt-4'>
                        <h3 className='font-gilroy-medium text-primary-black '>Referral Link</h3>
                        <div className='flex justify-end items-center'>
                            <a href='https://www.facebook.com/myAdvantageAfrica' target="__blank">
                                <IconSocial.FaFacebook
                                className='w-10 h-10 p-2 bg-light-purple text-facebook-blue  '/>
                            </a>
                            <a 
                            href='https://api.whatsapp.com/send?phone=2349139376140&text=Hi+myAdvantage+Team.+I%27m+interested+in+joining+your+platform.+I+need+to+know+more+about+your+plans' 
                            target="__blank">
                                <IconSocials.RiWhatsappFill
                                className='w-10 h-10 p-2 bg-light-purple text-primary-green  ml-2'/>
                            </a>
                        </div>
                    </div>

                    <div className='bg-gray-100 rounded grid grid-cols-3 mt-8'>
                        <p
                            className='col-span-2 py-1 px-1 break-words text-primary-purple font-gilroy-bold my-auto text-xs xl:text-sm'>https://customer.myadvantage.africa/plan-page?referral={data.referralCode}</p>
                        <button
                            className='bg-primary-purple text-white px-2 py-4 rounded ml-auto text-xs xl:text-base' onClick={
                              ()=>{
                                copyToClipBoard(`https://customer.myadvantage.africa/plan-page?referral=${data.referralCode}`)
                              }
                            }>Copy link</button>
                    </div>

                </div>

                <div className='' onClick={onMlmHandler}>
                    <div className='bg-white px-4 py-2 rounded'>
                        <h3 className='font-gilroy-semibold'>Current Rank:{" "}
                            <span className='text-primary-purple font-gilroy-bold'>{customer.customerlevel}</span>
                        </h3>

                        <div className='grid grid-cols-4 items-start'>
                            <div className="relative pt-1 col-span-3 gap-2">
                                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-border-color ">
                                    <div
                                        style={{
                                        width:`${upgradeData? upgradeData.percentage : '0%'}`
                                    }}
                                        className="shadow-none flex rounded flex-col text-center whitespace-nowrap text-white justify-center bg-primary-purple"></div>
                                </div>

                                <div className='flex items-center justify-between'>

                                    <div className='p-2 bg-require-blue rounded-xl'>
                                        <p className='font-gilroy-semibold text-xs'>4 {customer.customerlevel}</p>
                                        <p className='text-center text-xxs'>Required</p>
                                    </div>

                                    <div className='p-2 bg-require-green rounded-xl'>
                                        <p className='font-gilroy-semibold text-xs'>{upgradeData? (upgradeData.percentage/100)*4 : '0'} {customer.customerlevel}</p>
                                        <p className='text-center text-xxs'>Achieved</p>
                                    </div>

                                </div>

                            </div>

                            <p className='text-right xl:text-center font-gilroy-semibold leading-3 text-sm'>{upgradeData? upgradeData.percentage : '0'}%</p>
                        </div>

                    </div>

                    <div className='bg-white px-4 py-2 rounded'>
                        <h3 className='font-gilroy-semibold'>
                            Next Rank: <span className='text-primary-purple capitalize font-gilroy-bold'> {onlevelhandler(parseInt(upgradeData.nextlevel)+1)}</span>
                        </h3>

                        <div className='grid grid-cols-4 items-start'>
                            <div className="relative pt-1 col-span-3 gap-2">
                                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-border-color ">
                                    <div
                                        style={{
                                        width: '0%'
                                    }}
                                        className="shadow-none rounded flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-purple"></div>
                                </div>

                                <div className='flex items-center justify-between'>

                                    <div className='p-2 bg-require-blue rounded-xl'>
                                        <p className='font-gilroy-semibold text-xs'>4 {onlevelhandler(parseInt(upgradeData.nextlevel)+1)}</p>
                                        <p className='text-center text-xxs'>Required</p>
                                    </div>

                                    <div className='p-2 bg-require-green rounded-xl'>
                                        <p className='font-gilroy-semibold text-xs'>4 {onlevelhandler(parseInt(upgradeData.nextlevel)+1)}</p>
                                        <p className='text-center text-xxs'>Achieved</p>
                                    </div>

                                </div>

                            </div>

                            <p className='text-right xl:text-center font-gilroy-semibold leading-3 text-sm'>0%</p>
                        </div>

                    </div>
                </div>

                <div className='' >
                    <div className='bg-white px-4 py-4 rounded relative' onClick={onMlmHandler}>
                        <div className='flex justify-start items-center '>
                            <Icons.TbCurrencyNaira className=' text-2xl'/>
                            <h1 className=' text-primary-black text-2xl font-gilroy-medium '>
                                0.00
                            </h1>
                        </div>
                        <p className='mt-2 text-xs font-gilroy-medium'>Cummulative Referral Earnings</p>

                        <img src={images.doubleCircle[0]} alt={images.doubleCircle[1]} className="absolute top-0 right-0 w-16"/>
                    </div>

                    <div className='bg-white px-4 py-4 rounded mt-6 ' onClick={onMlmHandler}>
                        <h1 className=' text-primary-black text-lg font-gilroy-bold '>
                            {data?.upliner !== null && data?.upliner !== "null" ? 
                            `${data?.upliner}` : 
                            data?.kyc !== null && data?.kyc?.upliner !== null 
                            && data?.kyc?.upliner !== "null" ? `${data?.kyc?.upliner}` : "No sponsor"}
                        </h1>
                        <p className='mt-2 text-sm font-gilroy-semibold'>Sponsor</p>
                        <p
                            className='mt-2 text-sm font-gilroy-semibold text-primary-purple flex items-center'>Rank : {customer.customerlevel}
                            <span>
                                <img
                                    src={images.boxIcon[0]}
                                    alt={images.boxIcon[1]}
                                    className="w-3 h-3 inline-block ml-2"/>
                            </span>
                        </p>
                    </div>

                </div>

            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>

                <div className=' mt-8 bg-white px-4 py-4 rounded'>
                    <div className=" flex justify-between items-center flex-wrap ">
                        <div className='flex justify-center items-center'>
                            <h3 className='font-gilroy-medium '>Transaction History</h3>
                            <button>
                                <IconEye.MdOutlineArrowBackIos className='ml-4 rotate-90'/>
                            </button>
                        </div>

                        <div
                            className='flex justify-around items-center px-2 py-3 text-xs font-gilroy-medium text-link-color bg-primary-border'>
                            Start date {moment().format('l')}
                        </div>
                    </div>

                    <TransactionTable/>

                </div>

                <div className='mt-8 bg-white px-4 py-2 rounded' onClick={onMlmHandler}>

                    <div className=" flex justify-between items-center flex-wrap">

                        <div className='flex justify-center items-center'>
                            <h3 className='font-gilroy-medium '>All members</h3>
                            <button>
                                <IconEye.MdOutlineArrowBackIos className='ml-4 rotate-90'/>
                            </button>
                        </div>

                        <div className='flex items-center '>
                            <h3 className='mr-2 text-link-color'>Monthly plan</h3>
                            <div className='flex justify-around items-center px-4 py-1 bg-primary-border'>
                                <div className='flex flex-col justify-center items-center'>
                                    <div className='bg-primary-green opacity-50 p-3 py-3'></div>
                                    <p className='text-center mt-1 text-xxs'>Paid</p>
                                </div>

                                <div className='ml-3 flex flex-col justify-center items-center'>
                                    <div className='bg-primary-red opacity-50 p-3 py-3'></div>
                                    <p className='text-center mt-1 text-xxs'>Not paid</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <DashboardTreeTable/>

                </div>

            </div>
            </>
            )}

        </HOC>

    )
}

export default DashboardPage