import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../context/auth-context';
import { useHttpClient } from '../../../../hooks/less-http-hook';
import Loader from '../../../../UI_Elements/loader/Loader';
import { images } from '../../../../assets/images';
import Input from '../../../../UI_Elements/Input/Input';
import { VALIDATOR_REQUIRE } from '../../../../util/validators';
import { useForm } from '../../../../hooks/form-hook';

function Withdraw(props) {

    const auth = useContext(AuthContext);

    const [banks, setBanks] = useState(null)

    const [bankCode, setBankCode] = useState(null)

    const {isLoading, error, sendRequest} = useHttpClient();

    const [success, setSuccess] = useState(false);

    const [formState,
        inputHandler] = useForm({

        bank_account_name: {
            value: '',
            isValid: false
        },
        bank_account_number: {
            value: '',
            isValid: false
        }
    }, false);

    
    useEffect( () => {
        setSuccess(false)
        const referenceRequest = async() => {
            try {
        
                const responseData = await
                sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/wallet/banks`, 'GET', null, {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token
                });
                
                if(responseData){
                  setBanks(responseData.banks)
                }
        
            } catch (err) {
                
                console.log(error)
            }
        }

        referenceRequest()
    }, [auth.token, error, props.config, sendRequest])

    const paymentHandler = async() => {

        try {
            console.log(bankCode)
            const data = JSON.stringify({
                "amount": props.config.amount.toString(),
                "bank_account_name": formState.inputs.bank_account_name.value,
                "bank_account_number": formState.inputs.bank_account_number.value,
                "bank_account_code": bankCode
            })

            
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/wallet/transfer/create`, "POST", data, {'Content-Type': 'application/json',Authorization: 'Bearer '+auth.token});
            if(responseData){
              setSuccess(!success)
            }
        } catch (err) {
            console.log(error)
        }
    }


    const handleSelectChange = (event) =>{
        
        setBankCode(event.currentTarget.value)
    }

  return (
    <>
        {isLoading && <Loader/>}
        {!isLoading && 
        (<div>
        {success ? (
            <div className={`flex flex-col justify-center items-center ${isLoading ? 'hidden' : 'block'}`}>
                <img src={images.success[0]} alt={images.success[1]} className="object-contain"/>
                <h3 className='text-primary-black text-lg font-gilroy-bold mt-2 mb-8'>Withdrawal successful</h3>

                <p className='text-link-color'>{`You have successfully withdrawn NGN${props.config.amount} your naira wallet`}</p>
            </div>
        ): (
            <div className={`flex justify-center items-start flex-col`}>
                
                <div className='overflow-y-auto w-full'>
                <label htmlFor="countries" className="block mb-2 text-sm font-medium text-primary-black ">Bank</label>
                <select 
                id="countries" 
                className="bg-gray-50 border border-border-color text-gray-900 text-sm 
                rounded-lg  focus:ring-primary-purple focus:border-border-color block w-full p-2.5" 
                onChange={handleSelectChange}>
                    <option selected>Select bank</option>
                    {banks && banks.map((data, index) => {
                        return (
                            <option key={index} value={data.code}>
                                {data.name}
                            </option>
                        )
                    })}
                </select>
                </div>
                
                <Input
                    type="text"
                    placeholder='Bank account name'
                    label="Bank Account Name"
                    initialValue={props.config.customer.name}
                    initialValid={true}
                    labelClassName={`text-xs mb-1 mt-4 font-gilroy-extra-bold form_login_label`}
                    id="bank_account_name"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid account name"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    className={`appearance-none mb-4 rounded-none relative block w-full px-3 
                    py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-gray-900 
                    rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}/>

                
                <Input
                    type="text"
                    placeholder='Bank account number'
                    label="Bank Account Number"
                    labelClassName={`text-xs mb-1 mt-4 font-gilroy-extra-bold form_login_label`}
                    id="bank_account_number"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid account number"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    className={`appearance-none mb-4 rounded-none relative block w-full px-3 
                    py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input 
                    text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black 
                    focus:border-primary-black focus:z-10 sm:text-sm`}/>

                <button 
                className={`bg-primary-black px-6 py-2 text-white mx-auto rounded-sm 
                hover:bg-primary-purple cursor-pointer ${isLoading ? 'hidden' : 'block'}`} 
                
                onClick={() => {
                    paymentHandler()
                }}>
                    Continue
                </button>
            </div>
        )}
        </div>)
}
    </>
  )
}

export default Withdraw