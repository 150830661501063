import React, { useContext, useEffect, useState } from 'react'

import axios from 'axios'

import Loader from '../../../UI_Elements/loader/Loader';

import Avatar from '../../UI_Elements/Avatar/Avatar'

import { AuthContext } from '../../../context/auth-context';
import { DataContext } from '../../context/DataContext';

import { useHttpClient } from '../../../hooks/http-hook';

function ProfileAvatar(props) {
    const [profileImage, setProfileImage] = useState(null);
    const [isLoadingProfile, setisLoadingProfile] = useState(false);
    

    const auth = useContext(AuthContext);
    const data = useContext(DataContext);


    const {sendRequest} = useHttpClient();

    useEffect(() => {
        setProfileImage(props.photoUrl)
    }, [props.photoUrl])

    const readerHandler = (file) =>{
        const reader = new FileReader();
        reader.onload = (e) => {
            setProfileImage(e.target.result)
        }

        reader.readAsDataURL(file)
    }

    const profileHandler = async(incomingFile) =>{
        const file = incomingFile
        

        try{
            const formData = new FormData();
                    
            formData.append("file", file);
            formData.append('upload_preset', 'myadvantage-profile');
    
            await axios.post("https://api.cloudinary.com/v1_1/advantage-health-africa/auto/upload",formData).then(async(res) =>{
                
                const imageData = new FormData()   

                imageData.append("photo_url",res.data.secure_url)

                const url = `/v1/customers/upload/profile/img`

                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${url}`, "POST", imageData, {Authorization: 'Bearer '+auth.token});
                if(responseData){
                    data.UpdateData(null, null, null, null, res.data.secure_url)
                    console.log(data.image)
                    setisLoadingProfile(false)
                }  
                

            })
        }
        catch(e) {
            console.log(e)
            setisLoadingProfile(false)
        }

        

    }

    

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4'>
        <div className='flex flex-col justify-center items-start'>
        <h3 className='text-lg font-gilroy-semibold'>Profile picture</h3>
        <label htmlFor='profile' className='bg-transparent border-b border-primary-black p-0 text-sm mt-2 text-left cursor-pointer'>
            Tap to change profile picture
        </label>
        </div>

        <div className=''>
            
            <div className='relative mr-auto w-28 h-24 rounded-full col-span-2'>
                {isLoadingProfile && (
                    <div className='absolute top-0 left-0 rounded-full w-full h-full bg-slate-300'>
                        <Loader/>
                    </div>
                )}
                <Avatar src={profileImage} alt="account profile"  className="mr-auto w-28 h-24 rounded-full col-span-2" />
            </div>
            

            <input type="file"  
                className='w-1 h-1 opacity-0 ' accept='image/*' id='profile' onChange={(e) => {
                    setisLoadingProfile(true)
                    profileHandler(e.target.files[0]); 
                    readerHandler(e.target.files[0])
                }}/>
        </div>

        <button className={`text-white text-sm bg-primary-purple py-4 px-10 md:col-span-2 
        lg:col-span-1 xl:px-16 w-fit h-fit rounded my-auto`} 
        onClick={props.saveSubmitHandler}>
            {`${props.isLoading ? "Processing..." :"Save Profile Changes"}`}
        </button>
  </div>

  )
}

export default ProfileAvatar