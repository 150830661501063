import React, {useContext, useEffect, useMemo, useState} from 'react'

// REACT HOOKS
import {useTable} from 'react-table'

import { useHttpClient } from '../../../hooks/less-http-hook'
import {AuthContext} from '../../../context/auth-context';


// DATA
import {DASHBOARD_TREE_COLUMNS} from '../../../util/columns/columns';
import EmptyTransactions from '../Placeholders/EmptyTransactions';
import { images } from '../../../assets/images';
import Loader from '../../../UI_Elements/loader/Loader';

import './tables.css'



function DashboardTreeTable() {    

    const auth = useContext(AuthContext)


    const [tableData, setTableData] = useState(null);

    const [cellData , setCellData] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)
        

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/list/downliners`, "GET", null , {Authorization: 'Bearer '+auth.token});
                setTableData(responseData.data)
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.data){
                        for(let item of file.data){
                            setCellData((cell)=>[...cell, {
                                "name": `${item.firstname} ${item.lastname}`,
                                "referral_code": `${item.referral_code}`,
                                "rank": `${item.customerlevel}`,
                                "downlinerscount": `${item.downlinerscount}`,
                                "subscription": item.subscriptionstatus
                                
                            }])
                        }
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth]);


    const columns = useMemo(() => DASHBOARD_TREE_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    })
    
    return (
        <> 
        {isLoading ? <Loader/> : 
        
        <div className=" sm:rounded-lg mt-4 overflow-x-auto">
            

            {
                tableData === null ? <EmptyTransactions src={images.emptyOne[0]} alt={images.emptyTwo[1]} text="Start referring and earning" /> : 
                <table {...getTableProps()} className="min-w-full text-left">
                    <thead className=''>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className=" px-2 space-x-1.5">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap p-2 py-4  ">{column.render('Header')}</th>
                                ))}
                            
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} className="p-2 hover:cursor-pointer hover:shadow-lg table-default-color hover:text-primary-black bg-white ">

                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} className={`text-xs whitespace-nowrap p-2 border-b border-white mb-2 font-gilroy-bold   ${row.original.subscriptionstatus? 'bg-primary-green ' : 'bg-primary-red'} bg-opacity-30`}>{cell.render('Cell')}</td>
                                    })}
                                
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            }
            <div className='w-full dash-h-1 bg-primary-black opacity-30'></div>
           
            
        </div>
}
        </>              
    )
}

export default DashboardTreeTable
