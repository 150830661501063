import React, { useContext, useEffect, useState } from 'react'
import HOC from '../hoc/HOC'

import Inputs from '../../UI_Elements/Input/Inputs'
import { useForm } from '../../hooks/form-hook'
import { VALIDATOR_REQUIRE } from '../../util/validators'
import { useHttpClient } from '../../hooks/less-http-hook'
import { AuthContext } from '../../context/auth-context'
import ProfileAvatar from '../components/profile/ProfileAvatar'
import Input from '../../UI_Elements/Input/Input'
import { useNavigate } from 'react-router-dom'
import { DataContext } from '../context/DataContext'

import { toast } from 'react-toastify'


function Settings() {

  const auth = useContext(AuthContext);
  const data = useContext(DataContext)
  data.updatePage("Profile")

  const history = useNavigate()

  const [photoUrl, setPhotoUrl] = useState(null)
  
  const [loadedData, setLoadedData] = useState(null)

  const {isLoading, error, sendRequest} = useHttpClient();

  const [formState,
    inputHandler, setFormData] = useForm({

      firstname: {
        value: '',
        isValid: true
      },
      lastname: {
        value: '',
        isValid: true
      },
      email: {
        value: '',
        isValid: true
      },
      phonenumber: {
        value: '',
        isValid: true
      },
      photo_url: {
        value: '',
        isValid: true
      },
      kyc: {
        value: '',
        isValid: true
      },
      referral_code: {
        value: '',
        isValid: true
      },
      gender:{
        value:'',
        isValid: true
      },
      postal_address:{
        value:'',
        isValid: true
      },
      
      kin_name:{
        value:'',
        isValid: true
      },
      kin_home_address:{
        value:'',
        isValid: true
      }, 
      kin_phone_number:{
        value:'',
        isValid: true
      },
      pharmacy_location:{
        value:'',
        isValid: true
      }, 
      pharmacy_name:{
        value:'',
        isValid: true
      },
      kin_relationship:{
        value:'',
        isValid: true
      },
      old_password:{
        value:'',
        isValid: true
      },
      new_password:{
        value:'',
        isValid: true
      }
  },false )

  useEffect(() => {
    const fetchData = async() => {
        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/profile`, "GET", null, {Authorization: 'Bearer '+auth.token});
            
            setLoadedData(responseData);
            
            
            setFormData(
                 {
                  firstname: {
                    value: responseData.customers?.firstname || "",
                    isValid: true
                  },
                  lastname: {
                    value: responseData.customers?.lastname || "",
                    isValid: true
                  },
                  email: {
                    value: responseData.customers?.email || "",
                    isValid: true
                  },
                  phonenumber: {
                    value: responseData.customers?.phone_number || "",
                    isValid: true
                  },
                  photo_url: {
                    value: responseData.customers?.photo_url || "",
                    isValid: true
                  },
                  gender:{
                    value: responseData.customers?.gender || "",
                    isValid: true
                  },
                  postal_address:{
                    value: responseData.customers?.postal_address || "",
                    isValid: true
                  },
                  kin_name:{
                    value:responseData.customers?.kyc?.next_of_kin_name || "",
                    isValid: true
                  },
                  kin_home_address:{
                    value:responseData.customers?.kyc?.house_address || "",
                    isValid: true
                  }, 
                  kin_phone_number:{
                    value:responseData.customers?.kyc?.next_of_kin_phone_number || "",
                    isValid: true
                  },
                  pharmacy_location:{
                    value:responseData.customers?.kyc?.pharmacy_location || "",
                    isValid: true
                  }, 
                  pharmacy_name:{
                    value:responseData.customers?.kyc?.pharmacy_name || "",
                    isValid: true
                  },
                  kin_relationship:{
                    value:'relative',
                    isValid: true
                  }

                },
                true
              );

              setPhotoUrl(responseData.customers.photo_url)

              

        } catch (err) {
            console.log(error)
        }
    };
    fetchData();
}, [sendRequest, error, setFormData, auth]);

  


  const saveSubmitHandler = async()=>{
    try {
      const data = JSON.stringify({
        "firstname": formState.inputs.firstname?.value || "",
        "lastname": formState.inputs.lastname?.value || "",
        "phone_number": formState.inputs.phonenumber?.value || "",
        "gender": formState.inputs.gender?.value || "",
        "postal_address": formState.inputs.postal_address?.value || "",
        "email": formState.inputs.email?.value || ""
      })

    const res = await
      sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/update`, 'POST', data, {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
      })

    if(res){
      toast.success("Details saved successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        }); 
    }

  } catch (err) {
      console.log(error)
  }
  }

  const passwordSubmitHandler = async()=>{
    try {
      const data = JSON.stringify({
        "oldpassword": formState.inputs.old_password.value,
        "newpassword": formState.inputs.new_password.value
      })

    const res = await
      sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/change/password`, 'POST', data, {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
      });

      if(res){
        toast.success("New Password saved successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          }); 
      }


  } catch (err) {
      console.log(error)
  }
  }

  const saveKycHandler = async(event)=>{
    event.preventDefault()
    try{
      if(loadedData){
        
        const datas = JSON.stringify({
          nationality : loadedData.customers?.kyc?.nationality || "",
          state_of_residence : loadedData.customers?.kyc?.state_of_residence || "",
          upliner : loadedData.customers?.kyc?.upliner || "",
          community_interest : loadedData.customers?.kyc?.community_interest || "",
          future_aspiration : loadedData.customers?.kyc?.future_aspiration || "",
          discount_preferences : loadedData.customers?.kyc?.discount_preferences || "",
          pre_existing_health_condtion: loadedData.customers?.kyc?.pre_existing_health_condtion || "",
          pre_existing_health_condtion_drug : loadedData.customers?.kyc?.pre_existing_health_condtion_drug || "",
          allergy : loadedData.customers?.kyc?.allergy || "",
          vbank_account_number : loadedData.customers?.kyc?.vbank_account_number || "",
          next_of_kin_name : formState.inputs?.kin_name?.value || "",
          next_of_kin_phone_number : formState.inputs?.kin_phone_number?.value || "",
          pharmacy_location : formState.inputs?.pharmacy_location?.value || "",
          pharmacy_name : formState.inputs?.pharmacy_name?.value || "",
          house_address : formState.inputs?.kin_home_address?.value || "",
      })

       const res = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/kyc/create`, 'POST', datas, {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
      });

      if(res){
        toast.success("KYC saved successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          }); 
      }

      }


    } catch (err) {
        console.log(error)
    }
  }

  const changeMembership = () =>{
    history(`/plan-page?token=${auth.token}&id=${loadedData.customers.customer_id}
              &email=${loadedData.customers.email}&lastname=${loadedData.customers.lastname}
              &firstname=${loadedData.customers.firstname}&phonenumber=${loadedData.customers.phone_number}`)
  }

  return (
    <>
    {loadedData && <HOC bgwhite>
    
     <ProfileAvatar saveSubmitHandler={saveSubmitHandler} isLoading={isLoading} photoUrl={photoUrl}/>

      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 my-12'>
        <div className='flex flex-col justify-start items-start '>
          <h3 className='text-lg font-gilroy-semibold'>Personal details</h3>
          <p className='bg-transparent  p-0 text-sm mt-2 text-left'>
            You can edit and save changes
          </p>
        </div>

        <div className='col-span-2'>
            <Inputs
                    type="text"
                    placeholder='Enter your first name'
                    label="First Name"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="firstname"
                    initialValue={loadedData.customers?.firstname || ""}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid firstname"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            <Inputs
                    type="text"
                    placeholder='Enter your last name'
                    label="Last Name"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="lastname"
                    initialValue={loadedData.customers?.lastname || ""}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid last name"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            <Inputs
                    type="text"
                    placeholder='Enter your Gender'
                    label="Gender"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="gender"
                    initialValue={loadedData.customers?.gender || ""}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid gender"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            <Inputs
                    type="text"
                    placeholder='Enter your phone number'
                    label="Phone Number"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="phone_number"
                    initialValue={loadedData.customers?.phone_number || ""}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid phone number"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>
            
            <Inputs
                    type="text"
                    placeholder='Postal Address'
                    label="Postal Address"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="postal_address"
                    initialValue={loadedData.customers?.postal_address || ""}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid postal address"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>
        </div>

      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 my-12'>
        <div className='flex flex-col justify-start items-start '>
          <h3 className='text-lg font-gilroy-semibold'>KYC information</h3>
          
        </div>

        <div className='col-span-2'>
            <Inputs
                    type="text"
                    readOnly
                    placeholder=''
                    label="Your unique code"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="unique_code"
                    initialValue={loadedData.customers?.referral_code || ""}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid unique code"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            <Inputs
                    type="text"
                    readOnly
                    placeholder=''
                    label="Sponsor (Referral code)"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="sponsor"
                    initialValue={loadedData.customers?.kyc?.upliner || ""}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid sponsor"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            <Inputs
                type="text"
                readOnly
                placeholder=''
                label="Nearest pharmacy location (Address)"
                labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                id="sponsor"
                initialValue={loadedData.customers?.kyc?.pharmacy_location || ""}
                initialValid={true}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid pharmacy location"
                onInput={inputHandler}
                containerClassName={``}
                className={`bg-transparent`}/>

              <Inputs
                type="text"
                readOnly
                placeholder=''
                label="Name of Nearest Pharmacy"
                labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                id="sponsor"
                initialValue={loadedData.customers?.kyc?.pharmacy_name || ""}
                initialValid={true}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid pharmacy name"
                onInput={inputHandler}
                containerClassName={``}
                className={`bg-transparent`}/>

            {/* <Inputs
                    type="text"
                    readOnly
                    placeholder=''
                    label="Vbank Account name"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="account_name"
                    initialValue={`${loadedData.customers.firstname} ${loadedData.customers.lastname}`}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid account name"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            <Inputs
                    type="text"
                    readOnly
                    placeholder=''
                    label="Vbank Account number"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="account_number"
                    initialValue={loadedData.customers.kyc.vbank_account_number}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid account number"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/> */}
            
            
        </div>

        <button 
        className='text-white text-sm bg-primary-purple py-4 px-8 md:col-span-2 lg:col-span-1 xl:px-16 w-fit h-fit rounded mx-auto' 
        onClick={saveKycHandler}>
          {`${isLoading ? "Processing..." :"Save Kyc Changes"}`}
        </button>

      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 my-12'>
        <div className='flex flex-col justify-start items-start '>
          <h3 className='text-lg font-gilroy-semibold'>Next of kin information</h3>
          <p className='bg-transparent p-0 text-sm mt-2 text-left'>
            You can edit and save changes
          </p>
        </div>

        <div className='col-span-2'>
        <Inputs
                    type="text"
                    placeholder='Enter kin name'
                    label="Kin Name"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="kin_name"
                    initialValue={loadedData.customers.kyc.next_of_kin_name}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid firstname"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            <Inputs
                    type="text"
                    placeholder='Home Address'
                    label="Home Address"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="kin_home_address"
                    initialValue={loadedData.customers.kyc.house_address}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid home address"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            <Inputs
                    type="text"
                    placeholder='phone number'
                    label="Phone number"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="phone_number"
                    initialValue={loadedData.customers.kyc.next_of_kin_phone_number}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid phone_number"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/>

            {/* <Inputs
                    type="text"
                    placeholder='Relationship'
                    label="Relationship"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="kin_relationship"
                    initialValue={"relative"}
                    initialValid={true}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid Relationship"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`bg-transparent`}/> */}
            
            
        </div>

        

      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 my-8'>
        <div className='flex flex-col justify-center items-start mb-auto'>
          <h3 className='text-lg font-gilroy-semibold'>Update password </h3>
          <p className='bg-transparent p-0 text-sm mt-2 text-left'>
          You can change your old password to a new one
          </p>
        </div>

        <div>
          <h3 className='text-lg mb-4 font-gilroy-semibold col-span-2 text-primary-purple'>Change password</h3>

          <Input
            type="password"
            passwordValue
            label="Old Password"
            labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
            id="old_password"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter more than 8 characters"
            onInput={inputHandler} 
            containerClassName={`relative`}
            className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}
            />
          <div className='mt-2'></div>
          <Input
            type="password"
            passwordValue
            label="New Password"
            labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
            id="new_password"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter more than 8 characters"
            onInput={inputHandler} 
            containerClassName={`relative`}
            className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}
            />
        </div>

        <button 
        className='text-white text-right text-sm bg-primary-purple py-4 px-8 md:col-span-2 lg:col-span-1 xl:px-16 w-fit h-fit rounded mx-auto' 
        onClick={passwordSubmitHandler}>
          {`${isLoading ? "Processing..." :"Save Password"}`}
        </button>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 my-8'>
        <div className='flex flex-col justify-center items-start'>
          <h3 className='text-lg font-gilroy-semibold'>Membership</h3>
          <button className='bg-transparent p-0 text-sm mt-2 text-left text-primary-red font-gilroy-bold'>
            Pause account
          </button>
        </div>

        <button onClick={changeMembership} className='text-lg mr-auto font-gilroy-semibold col-span-2 text-primary-purple'>Change membership</button>

        <div></div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 my-8'>
        <div className='flex flex-col justify-center items-start'>
          <h3 className='text-lg font-gilroy-semibold'>Wallet account limits</h3>
          
        </div>

        <div className='text-sm flex flex-col justify-center items-start font-gilroy-semibold col-span-2 '>
            <p>Daily withdrawal : 50,000NGN</p>
            <p>Maximum top up : 30,000NGN</p>
        </div>

        <div></div>
      </div>  

    
    </HOC>}
    </>
  )
}

export default Settings