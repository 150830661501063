import React, { useContext, useState } from 'react'



import HOC from '../hoc/HOC'

import ClaimsTable from '../components/Tables/ClaimsTable';
import RewardsTable from '../components/Tables/RewardsTable';
import { DataContext } from '../context/DataContext';

function ClaimsRewards() {
  const [rewards, setRewards] = useState(false);
  const [claims, setClaims] = useState(true);
  
  // const [refresh, setRefresh] = useState(true);

  const data = useContext(DataContext)

  data.updatePage("Claims & Rewards")
  const rewardsHandler = () =>{
    if(rewards){
      return
    }

    setRewards(!rewards)
    setClaims(!claims)

  }


  const claimsHandler = () =>{
    if(claims){
      return
    }

    setClaims(!claims)
    setRewards(!rewards)
  }
  return (
    <HOC >
      <div className='bg-white rounded-md text-sm font-gilroy-medium flex justify-start items-center p-1 w-fit'>
        <button 
        className={` p-2 px-3 hover:bg-primary-black ${!rewards ? "bg-primary-black text-white" : "text-primary-black"} 
        hover:text-white rounded-l-md `} 
        onClick={claimsHandler}>
          My Claims
        </button>
        <button className={` p-2 px-3 hover:bg-primary-black ${rewards ? "bg-primary-black text-white" : "text-primary-black"}  
        hover:text-white rounded-r-md `} 
        onClick={rewardsHandler}>
          My Rewards
        </button>
      </div>

      <div>
        {claims && <ClaimsTable/>}
        {rewards && <RewardsTable/>}
      </div>
    </HOC>
  )
}

export default ClaimsRewards
