import React, {useContext, useState} from 'react'
import {images} from '../../../../assets/images'
import {useForm} from '../../../../hooks/form-hook';
import {useHttpClient} from '../../../../hooks/status-http-hook';
import Input from '../../../../UI_Elements/Input/Input';
import {VALIDATOR_REQUIRE} from '../../../../util/validators'



import {AuthContext} from '../../../../context/auth-context';

function Bvn(props) {
    const {isLoading, error, sendRequest} = useHttpClient();

    const [remember,
        setRemember] = useState(false)

    const auth = useContext(AuthContext);

    const [formState,
        inputHandler] = useForm({   

        bvn: {
            value: '',
            isValid: false
        }
    }, false);

    const authSubmitHandler = async event => {
        event.preventDefault();

        try {

            const data = JSON.stringify({type: '0', document_link: formState.inputs.bvn.value})

            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/documents/create`, 'POST', data, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            });


            if(responseData){
                
                props.bvnChangeHandler("done")
            }

        } catch (err) {
            
            console.log(error)
        }
    };

    return (
        <div>
            
            <div className='flex justify-start items-center mb-6'>
                <img src={images.webBack[0]} alt={images.webBack[1]} className="mr-6 cursor-pointer" onClick={props.bvnChangeHandler}/>
                <p className="text-base font-gilroy-bold text-primary-purple ">NIN Verification</p>
            </div>

            <div className='flex justify-start items-center mb-4'>
                <div className='w-3 h-3 p-2 bg-primary-pink flex__center rounded-full text-sm text-error-red'>!</div>
                <p className='text-link-color text-sm ml-1'>Why we request for your NIN</p>
            </div>

            <form onSubmit={authSubmitHandler}>
                <Input
                    type="text"
                    placeholder='Enter your NIN'
                    label="NIN"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="bvn"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid bvn"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    className={`appearance-none mb-4 ${formState.inputs.bvn.value && "bg-light-purple"} rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-link-color rounded-t-md focus:outline-none focus:ring-primary-purple focus:border-primary-purple focus:bg-light- focus:z-10 sm:text-sm`}/>

                <div className="flex items-center my-4">
                    <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        onClick={() => setRemember(!remember)}
                        className="h-4 w-4 accent-orange-600 focus:ring-primary-purple border-primary-purple rounded "/>
                    <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-primary-gray font-extra">
                        I agree to myAdvantage’s
                        <span className='text-primary-purple'> privacy policy</span>
                    </label>
                </div>

                <div>
                    <button
                        type="submit"
                        className={`group relative  w-full flex items-center justify-around py-3 px-12 border border-transparent text-base font-gilroy-extra-bold rounded-md text-white bg-primary-purple hover:bg-primary-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-purple`}>
                        {isLoading
                            ? 'Processing...'
                            : 'Next'}
                    </button>
                </div>

            </form>
        </div>
    )
}

export default Bvn