/*eslint-disable*/
import React, { useContext } from "react";
import { Link, useLocation }  from "react-router-dom";

import {images} from '../../assets/images/index';

import * as Icons from "react-icons/gi";
import * as IconT from "react-icons/cg";
import * as IconTree from 'react-icons/im';
import * as IconReferral from 'react-icons/hi';
import * as IconWallet from 'react-icons/ri';
// import * as IconSetting from 'react-icons/ri';
import * as IconDashboard from 'react-icons/fa'

import UserDropdown from "../../components/Dropdowns/UserDropdown";
import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown"
import { DataContext } from "../context/DataContext";

export default function Sidebar(props) {
  const [collapseShow, setCollapseShow] = React.useState("hidden ");

  const data = useContext(DataContext)

  const location = useLocation();
  
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-md bg-white  flex flex-wrap items-center justify-between relative md:w-48 xl:w-64 z-10 ">
        <div className=" md:px-0 md:flex-col md:items-stretch md:min-h-full md:flex-nowrap flex-wrap flex items-center justify-between w-full mx-auto  ">
          {/* Toggler */}
          <button
            className="h-12 w-12 cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-base leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-black m-2 py-3 px-6")}
          >
            <Icons.GiHamburgerMenu/>
          </button>
          {/* Brand */}
          <Link
            className="md:block md:pb-2 mx-auto whitespace-nowrap text-lg font-logo px-6 py-3 bg-white"
            to="/dashboard/dashboard"
          >
            <img src={images.logo[0]} alt={images.logo[1]} className="h-10 md:h-auto"/>
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative mr-2 md:mr-0">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              " md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden bg-white h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm font-logo p-4 px-0"
                    to="/dashboard/dashboard"
                  >
                    <img src={images.logo[0]} alt={images.logo[1]} className=" h-10"/>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <IconT.CgCloseO className="text-primary-black text-xl font-extrabold"/>
                  </button>
                </div>
              </div>
            </div>
            
            {/* Divider */}
            {/* <hr className="my-4 md:min-w-full" /> */}
            {/* Heading */}
            
            {/* Navigation */}

            <ul className=" py-2 md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-sm capitalize py-3 font-bold px-3 " +
                    (location.pathname === "/dashboard/dashboard"
                      ? "text-primary-black border-l-4 border-primary-black bg-side-purple"
                      : "text-link-color  border-l-4  border-transparent  hover:border-primary-black ")
                  }
                  to="/dashboard/dashboard"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <IconDashboard.FaChartPie
                    className={
                      "fas fa-tv mr-2 text-lg " +
                      (location.pathname === "/dashboard/dashboard"
                        ? "text-primary-black"
                        : "text-link-color")
                    }
                  ></IconDashboard.FaChartPie>{" "}
                  Dashboard
                </Link>
              </li>


              <li className={`items-center ${!data.mlm && "hidden"}`}>
                <Link
                  className={
                    "flex items-center text-sm capitalize py-3 font-bold px-3 " +
                    (location.pathname === "/dashboard/tree"
                      ? "text-primary-black border-l-4 border-primary-black bg-side-purple"
                      : "text-link-color  border-l-4  border-transparent  hover:border-primary-black ")
                  }
                  to="/dashboard/tree"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <IconTree.ImTree
                    className={
                      "fas fa-tv mr-2 text-lg " +
                      (location.pathname === "/dashboard/tree"
                        ? "text-primary-black"
                        : "text-link-color ")
                    }
                  ></IconTree.ImTree>{" "}
                  Tree Genealogy
                </Link>
              </li>


              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-sm capitalize py-3 font-bold px-3 " +
                    (location.pathname === "/dashboard/wallet"
                      ? "text-primary-black border-l-4 border-primary-black bg-side-purple"
                      : "text-link-color  border-l-4  border-transparent  hover:border-primary-black ")
                  }
                  to="/dashboard/wallet"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <IconWallet.RiWallet3Fill
                    className={
                      "fas fa-tv mr-2 text-lg " +
                      (location.pathname === "/dashboard/wallet"
                        ? "text-primary-black"
                        : "text-link-color hover:text-primary-black")
                    }
                  ></IconWallet.RiWallet3Fill>{" "}
                  Wallet
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-sm capitalize py-3 font-bold px-3 " +
                    (location.pathname === "/dashboard/payment"
                      ? "text-primary-black border-l-4 border-primary-black bg-side-purple"
                      : "text-link-color  border-l-4  border-transparent  hover:border-primary-black ")
                  }
                  to="/dashboard/payment"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <img src={images.sFour[0]} 
                  alt={images.sFour[1]}
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (location.pathname === "/dashboard/payment"
                        ? "text-primary-black"
                        : "text-link-color")
                    }
                  ></img>{" "}
                  Payment
                </Link>
              </li>

              <li className={`items-center ${!data.mlm && "hidden"}`}>
                <Link
                  className={
                    "flex items-center text-sm capitalize py-3 font-bold px-3 " +
                    (location.pathname === "/dashboard/referral"
                      ? "text-primary-black border-l-4 border-primary-black bg-side-purple"
                      : "text-link-color  border-l-4  border-transparent  hover:border-primary-black ")
                  }
                  to="/dashboard/referral"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <IconReferral.HiGift
                    className={
                      "fas fa-tv mr-2 text-lg " +
                      (location.pathname === "/dashboard/referral"
                        ? "text-primary-black"
                        : "text-link-color")
                    }
                  ></IconReferral.HiGift>{" "}
                  Referral
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-sm capitalize py-3 font-bold px-3 " +
                    (location.pathname === "/dashboard/rewards"
                      ? "text-primary-black border-l-4 border-primary-black bg-side-purple"
                      : "text-link-color  border-l-4  border-transparent  hover:border-primary-black ")
                  }
                  to="/dashboard/rewards"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <img src={images.sSix[0]} alt={images.sSix[1]}
                    className={
                      "fas fa-tv mr-2 text-lg " +
                      (location.pathname === "/dashboard/rewards"
                        ? "text-primary-black"
                        : "text-link-color")
                    }
                  ></img>{" "}
                  Claims & Rewards
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-sm capitalize py-3 font-bold px-3 " +
                    (location.pathname === "/dashboard/help-center"
                      ? "text-primary-black border-l-4 border-primary-black bg-side-purple"
                      : "text-link-color  border-l-4  border-transparent  hover:border-primary-black  ")
                  }
                  to="/dashboard/help-center"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <img src={images.sSeven[0]} alt={images.sSeven[1]}
                    className={
                      "fas fa-tv mr-2 text-lg " +
                      (location.pathname === "/dashboard/help-center"
                        ? "text-primary-black"
                        : "text-link-color ")
                    }
                  ></img>{" "}
                  Help Center
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "flex items-center text-sm capitalize py-3 font-bold px-3 " +
                    (location.pathname === "/dashboard/profile"
                    ? "text-primary-black border-l-4 border-primary-black bg-side-purple"
                    : "text-link-color  border-l-4  border-transparent  hover:border-primary-black  ")
                  }
                  to="/dashboard/profile"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <img src={images.sEight[0]} alt={images.sEight[1]}
                    className={
                      "fas fa-tv mr-2 text-lg " +
                      (location.pathname === "/dashboard/profile"
                        ? "text-primary-black"
                        : "text-link-color hover:text-primary-black")
                    }
                  ></img>{" "}
                  Profile
                </Link>
              </li>

              {/* <li className="items-center">
                <Link to='/login'
                  className="text-blueGray-700 hover:text-blueGray-500 text-sm uppercase py-3 font-bold block"
                  onClick={logout}
                >
                  <i className="fas fa-fingerprint text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Logout
                </Link>
              </li> */}

             </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
