import React, {useContext, useState, useEffect} from 'react'
import { Route, Routes, Navigate } from "react-router-dom";


import Sidebar from './Sidebar/Sidebar';

import AdminNavbar from '../default/header/AdminNavbar';

import Settings from './sections/Settings';
import DashboardPage from './sections/DashboardPage';


import {AuthContext} from '../context/auth-context';
import {DataContext} from './context/DataContext';
import { useHttpClient } from '../hooks/less-http-hook';

import './Dashboard.css'
import Loader from './UI_Elements/Loader/Loader'
import Payments from './sections/Payments';
import Wallet from './sections/Wallet';
import Referral from './sections/Referral';
import HelpRequest from './sections/HelpRequest';
import Tree from './sections/Tree';
import KycModal from './components/kyc/KycModal';
import ClaimsRewards from './sections/ClaimsRewards';



function Dashboard() {
    const auth = useContext(AuthContext); 
     

    const [firstname,  setFirstName] = useState("")
    const [lastname,  setLastName] = useState("")
    const [email,  setEmail] = useState("")
    const [image,  setImage] = useState(null)
    const [phoneNumber,  setPhoneNumber] = useState(null)
    const [checkbvn,  setCheckbvn] = useState(null)
    const [checkKyc,  setCheckKyc] = useState(true)
    const [referralCode,  setReferralCode] = useState(null)
    const [postalAddress,  setpostalAddress] = useState(null)
    const [kyc,  setKyc] = useState(null)
    const [status,  setStatus] = useState(null)
    const [upliner,  setUpliner] = useState(null)
    const [gender,  setGender] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [balance, setBalance] = useState(null)
    const [mlm, setMlm] = useState(null)
    const [currentPage, setCurrentPage] = useState("Dashboard")

    function dataCollection(firstname, lastname, email,phonenumber, image){
        if(image){
            return setImage(image)
        }

        setFirstName(firstname);
        setLastName(lastname);
        setEmail(email);
        setPhoneNumber(phonenumber);
        // setGender(gender)
        // setpostalAddress(postalAddress)
        

    }

    const currentPageHandler = (page) =>{
        return setCurrentPage(page)
    }

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/profile`, "GET", null, {Authorization: 'Bearer '+auth.token});

                if(responseData){

                    setFirstName(responseData.customers.firstname);
                    setLastName(responseData.customers.lastname);
                    setEmail(responseData.customers.email);
                    setPhoneNumber(responseData.customers.phone_number);
                    setImage(responseData.customers.photo_url);
                    setCheckbvn(responseData.customers.checkbvn)
                    setKyc(responseData.customers.kyc)
                    setReferralCode(responseData.customers.referral_code)
                    setStatus(responseData.customers.status)
                    setUpliner(responseData.customers.upliner)
                    setpostalAddress(responseData.customers.postal_address)
                    setGender(responseData.customers.gender)
                    setCheckKyc(responseData.customers.checkyc)
                    setMlm(responseData.customers.checkmln)
                    setSubscription(responseData.customers.subscripionstatus)
                    setBalance(responseData.customers.balance)
                }

            } catch (err) {
                console.log(error)
            }
        };
        fetchData();
    }, [sendRequest, error, auth]); 

    return (
        <div>   
        {isLoading ? <Loader/> : 
            <DataContext.Provider 
            value={{
                firstname:firstname,
                lastname:lastname,
                email:email,
                phonenumber:phoneNumber,
                image:image,
                checkbvn,
                referralCode,
                postalAddress,
                kyc,
                status,
                upliner,
                gender,
                subscription,
                balance,
                mlm,
                currentPage:currentPage ,
                updatePage:currentPageHandler,
                UpdateData:dataCollection
            }}>
           
            <Sidebar/>
            <div className="relative md:ml-48 xl:ml-64 min-h-max ">
                
                {!checkKyc && <KycModal show={!checkKyc}/>}
                <AdminNavbar /> 
                <div className="px-4 mx-auto w-full pb-32 pt-4 dashboard_background " >
                    <Routes>
                        <Route path='dashboard' exact element={<DashboardPage/>} />
                        <Route path='tree' exact element={<Tree/>} />
                        <Route path='wallet' exact element={<Wallet/>} />
                        <Route path='payment' exact element={<Payments/>} />
                        <Route path='referral' exact element={<Referral/>} />
                        <Route path='rewards' exact element={<ClaimsRewards/>} />
                        <Route path='help-center' exact element={<HelpRequest/>} />
                        <Route path='profile' exact element={<Settings/>} />
                        <Route path="*" element={<Navigate to ="dashboard" />}/>
                    </Routes>
                </div>
            
            
        </div>

        </DataContext.Provider>
        }

        </div>
    )
}

export default Dashboard