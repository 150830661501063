import React, { useContext, useEffect, useRef, useState } from 'react'
import HOC from '../hoc/HOC'

import * as Icons from 'react-icons/ai'
import Inputs from '../../UI_Elements/Input/Inputs'
import { VALIDATOR_REQUIRE } from '../../util/validators'
import { useForm } from '../../hooks/form-hook'
import { useHttpClient } from '../../hooks/less-http-hook'
import { AuthContext } from '../../context/auth-context'
import Loader from '../../UI_Elements/loader/Loader';
import { DataContext } from '../context/DataContext'

function HelpRequest() {
  const auth = useContext(AuthContext)
  const [open, setOpen] = useState(null)
  const [cellData , setCellData] = useState([]);
  const refOne = useRef(null)

  const data = useContext(DataContext)
  data.updatePage("Help Request")

  const {isLoading, error, sendRequest} = useHttpClient();

  const [formState,
    inputHandler] = useForm({

      subject: {
          value: '',
          isValid: false
      },
      detail: {
          value: '',
          isValid: false
      }
    }, false);

  
    useEffect(() => {
      const fetchData = async() => {
            
        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/admin/faq/list?page_number=1000`, "GET", null , {Authorization: 'Bearer '+auth.token});
            

            if(responseData){
                setCellData([])
                for(let file of responseData.faq.data){
                    setCellData((cell)=>[...cell, {
                        "id":file.id,
                        "faq_question":file.faq_question,
                        "faq_answer": file.faq_answer
                    }])
                }
            }



        } catch (err) {
            console.log(error)
        }
    };

    fetchData();
    }, [sendRequest, error, auth])

    useEffect(() => {
      
      document.addEventListener("click", hideOnClickOutside, true)
    }, [])


    const hideOnClickOutside = (e) => {
      if( refOne.current && !refOne.current.contains(e.target) ) {
        setOpen("")
      }
    }

    const closeFaqHandler= (index) =>{
      console.log(open)
      if(open===null){
        setOpen(index)
      }
      else{
        setOpen(null)
      }
    }

  const authSubmitHandler = async(event) =>{
    event.preventDefault();

    console.log("i ran here")

    const data = JSON.stringify({
      "title": formState.inputs.subject.value,
      "description": formState.inputs.detail.value
    })

    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/help_center/create`, "POST", data, {Authorization: 'Bearer '+auth.token, contentType:"application/json"});

      if(responseData){
        console.log(responseData)
      }

  } catch (err) {
      console.log(error)
  }
  }

  return (
    <HOC bgwhite>
      <h3 className='text-xl font-gilroy-bold pt-6 '>Frequently Asked Questions</h3>

      <div ref={refOne} className="">
      {isLoading ? <Loader/> : cellData && cellData.map((data, index) => {
        return (

          <div className='bg-primary-purple rounded lg:w-4/5 my-8 transition-all delay-300' key={index}>
            <div className='flex justify-between items-center p-4 px-8'>
              <h3 className='text-white font-gilroy-semibold'>{data.faq_question}</h3>
              <Icons.AiOutlinePlus className='text-white cursor-pointer' onClick={() =>closeFaqHandler(index)}/>
            </div>
              <p className={`${open === index ? "block" : "hidden"} text-white text-sm p-4 py-2`}>{data.faq_answer}</p>
          </div>
        )
      })}
      </div>

      <div className='pt-6'>
        <h3 className=' font-gilroy-semibold'>Help Request</h3>

        <form className="mt-8 lg:w-4/5" onSubmit={authSubmitHandler} >
            
                <Inputs
                    type="text"
                    placeholder='i.e Withdrawal subject'
                    label="Subject"
                    required
                    labelClassName={`text-xs mb-1 form_login_label`}
                    id="subject"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid email"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    className={`py-12`}
                    style={{paddingTop:'2rem', paddingBottom:'2rem'}}/>


                <Inputs
                    placeholder='i.e Details of request'
                    label="Detail"
                    required
                    labelClassName={`text-xs mb-1 form_login_label`}
                    id="detail"
                    rows={6}
                    validators={[VALIDATOR_REQUIRE(8)]}
                    errorText="Please enter your help request"
                    onInput={inputHandler} 
                    containerClassName={`relative`}
                    className={`py-12`}
                    style={{height:"auto"}}
                    />

           
            <div>
              <button
                type="submit"
                className="group relative flex items-center justify-around py-3 px-20 
                border border-transparent text-sm font-gilroy-medium rounded-md text-white 
                bg-primary-purple hover:bg-primary-purple focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary-purple"
              >

                {isLoading ? 'Processing...' : 'Submit'}
              </button>
            </div>
          </form>
      </div>
    </HOC>
  )
}

export default HelpRequest