import React, { useContext } from 'react'

// import * as Icons from "react-icons/bs";
import { LockClosedIcon } from '@heroicons/react/solid'

// custom hooks
import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';
import {AuthContext} from '../context/auth-context';

//custom utils
import {VALIDATOR_EMAIL, VALIDATOR_REQUIRE} from '../util/validators'
import { Link, useNavigate } from 'react-router-dom';
import './auth.css'

//custom components
import Input from '../UI_Elements/Input/Input'
import { images } from '../assets/images';
// import RedirectPaymentModal from './components/RedirectPaymentModal';


const Login = () => {

    const history = useNavigate()

    const auth = useContext(AuthContext);

    // const [status, setStatus] =  useState(false)

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        }
    }, false);


    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {

            const data = JSON.stringify({
                email: formState.inputs.email.value,
                password: formState.inputs.password.value
            })

            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/login`, 'POST', data, {'Content-Type': 'application/json'});

            if(responseData.customer.subscripionstatus === false){
              
              return history(`/plan-page?token=${responseData?.token}
              &id=${responseData?.customer?.customer_id}
              &email=${responseData?.customer?.email}&lastname=${responseData?.customer?.lastname}
              &firstname=${responseData?.customer?.firstname}&phonenumber=${responseData?.customer?.phone_number}`)
            }
            auth.login(responseData.token);
            history("/dashboard")

        } catch (err) {
            
            console.log(error)
        }
    };

    // const onCancelRedirect = () =>{
    //   setStatus(!status)
    // }

  return (
    <>

    {/* <RedirectPaymentModal show={status} onCancel={onCancelRedirect}/> */}
      <div className="relative min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8 background__auth">
        
        <div className="max-w-xl relative md:max-w-5xl w-full space-y-8">
        
          <div>
            <a href='https://myadvantage.africa/'>
              <img
                src={images.logo[0]}
                alt={`Logo`}
                className="mx-auto h-20 w-auto"
              />
            </a>
            
            <div className='grid grid-cols-1 md:grid-cols-6'>
              <a href='https://myadvantage.africa/'  className='flex items-center mr-6'>
                  <img src={images.webBack[0]} alt={images.webBack[1]}/>
              </a>
              <h2 
              className="mt-6 text-center md:col-span-4 text-3xl lg:text-5xl font-gilroy-extra-bold text-primary-orange-title">Member Login</h2>
              <div></div>
            </div>
            
          </div>  

          <form className="mt-8 space-y-6 form_login " onSubmit={authSubmitHandler} >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">

                <Input
                    type="email"
                    placeholder='sampleemail@gmail.com'
                    label="Email Address"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="email"
                    validators={[VALIDATOR_EMAIL()]}
                    errorText="Please enter a valid email"
                    onInput={inputHandler}
                    divClassName={`mb-4 `}
                    containerClassName={`relative  `}
                    className={`appearance-none ${formState.inputs.email.value && "bg-light-purple"}  
                    rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 
                    placeholder-gray-500 form_login_input text-primary-black rounded-t-md 
                    focus:outline-none focus:ring-primary-purple focus:border-primary-purple 
                    focus:bg-light-purple focus:z-10 sm:text-sm`}/>


                <Input
                    type="password"
                    passwordValue
                    placeholder=''
                    label="Password"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="password"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter more than 8 characters"
                    onInput={inputHandler} 
                    containerClassName={`relative`}
                    className={`appearance-none rounded-none ${formState.inputs.password.value && "bg-light-purple"} 
                    relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input 
                    focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple focus:z-10 sm:text-sm`}
                    />
                
              
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between">
                
            
              <div className="text-sm">
                <Link to="/resetpassword" className="font-gilroy-bold text-xs text-primary-purple text-opacity-60 hover:text-opacity-100">
                  Forgot your password?
                </Link>
              </div>

              <div className="text-sm">
                <Link to="/referral" className="font-gilroy-bold text-xs text-primary-purple text-opacity-60 hover:text-opacity-100">
                  Don't have an account?
                </Link>
              </div>

              
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex items-center mx-auto md:mx-0 justify-around py-2 px-12 
                border border-transparent text-base font-gilroy-extra-bold rounded-md text-white 
                bg-primary-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-purple"
              >
                <span className=" flex items-center mr-3">
                  <LockClosedIcon className="h-5 w-5 text-white group-hover:text-white" aria-hidden="true" />
                </span>
                {isLoading ? 'Processing...' : 'Login'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


export default Login