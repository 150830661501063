import React, {useMemo, useEffect, useState, useContext} from 'react'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';


import {AuthContext} from '../../../context/auth-context';
import { useHttpClient} from '../../../hooks/less-http-hook'


// DATA
import {CLAIMS_COLUMNS} from '../../../util/columns/columns';
import TablePagination from '../../UI_Elements/TablePagination/TablePagination';

import Loader from '../../../UI_Elements/loader/Loader';

import './tables.css'



function ClaimsTable(props) {    

    const auth = useContext(AuthContext)

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"15",
        "status":"0",
        "search_text":""
    })

    const [pages, setPages] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);


        const fetchClaims = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/admin/claims/assignment/customerlist?page_number=20&type=claim`, "GET", null , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
                setTableData(responseData?.claim);
                setPages(responseData?.claim?.links)

                if(responseData){
                    setCellData([])
                    for(let file of responseData?.claim?.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.id,
                            "date": moment(file?.claims?.created_at).format('l') || "",
                            "claim_name": file?.claims?.reward_name || "",
                            "details": file?.claims?.description || "",
                            "vendor_name": file?.claims?.vendor_name || "",
                            "claim_type":file?.claims?.type || "",
                            "vendor_link":file?.claims?.link || "",
                            "status":file?.status || ""
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchClaims();

    }, [sendRequest, error, auth, detailsData, refresh]);


    const updateStatus = async(id,link) => {
        window.open(link);

        const data = JSON.stringify({
            id: parseInt(id),
            status:"1"
        })

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/admin/claims/assignment/change-status/customer`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});

            if(responseData){
                setRefresh(!refresh)
            }

        } catch (err) {
            console.log(error)
        }
    };



    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const columns = useMemo(() => CLAIMS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>
        
        {isLoading ? <Loader/> :
        
        <div className="bg-white pb-8 p-4 shadow-md sm:rounded-lg mt-6 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='box-b-1'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} >
                            <th className="text-xs whitespace-nowrap py-2">SN</th>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap py-2">{column.render('Header')}</th>
                                ))}
                            
                            <th>Status</th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} className='box-b-1'>
                                <td className="text-sm py-4 whitespace-nowrap font-gilroy-medium">{index+1}</td>
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-4 whitespace-nowrap font-gilroy-medium">{cell.render('Cell')}</td>
                                    })}
                                
                                <td className='text-sm py-4 font-gilroy-bold whitespace-nowrap'>
                                    <a href={row.original?.vendor_link} rel='noreferrer' target='_blank' className='cursor-pointer'>
                                    <button className={`${row.original?.status === '0' ? 'text-primary-purple' : ' text-gray-600'}`} 
                                    onClick={() => updateStatus(row.original?.id, row.original?.vendor_link)} >
                                    {/* disabled={row.original?.status === '0' ? false : true} */}
                                        {`${row.original?.status === '0' ? 'Claim' : 'Claiming'}`} 
                                    </button>
                                    </a>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {tableData?.current_page}
                last_page = {tableData?.last_page}
                prev_page_url = {tableData?.prev_page_url}
                next_page_url = {tableData?.next_page_url}
                pages = {pages}
                per_page = {tableData?.per_page}
                first_page_url = {tableData?.first_page_url}
                last_page_url={tableData?.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}

            
            
        </div>
        }
        </>              
    )
}

export default ClaimsTable
