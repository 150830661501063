import { createContext } from 'react';

export const DataContext = createContext({
    firstname:null,
    lastname:null,
    email:null,
    phonenumber:null,
    image:null,
    checkbvn:null,
    referralCode:null,
    postalAddress:null,
    kyc:null,
    status:null,
    upliner:null,
    gender:null,
    subscription:null,
    balance:0,
    mlm:true,
    currentPage:"Dashboard",
    updatePage:function(){},
    UpdateData:function(){}
});
 