import React, { useContext, useEffect, useState } from 'react'
import HOC from '../hoc/HOC'

import * as IconEye from "react-icons/bs";
import Card from '../components/card/Card';
import { images } from '../../assets/images';
import CardTopUpModal from '../components/walletModals/CardTopUpModal';
import WithdrawTopUpModal from '../components/walletModals/WithdrawModal';
import WalletTransactionTable from '../components/Tables/WalletTransactionTable'
import { useHttpClient } from '../../hooks/less-http-hook';
import { AuthContext } from '../../context/auth-context';
import Number from '../../UI_Elements/Input/Number';
import { DataContext } from '../context/DataContext';


function Wallet() {

  const auth = useContext(AuthContext);
  
  const data = useContext(DataContext)

  data.updatePage("Wallet")

  const [eye, setEye] = useState(true)

  const [cardTopUp, setCardTopUp] = useState(false)

  const [withdraw, setWithdraw] = useState(false)

  const [customer, setCustomer] = useState(null)

  const xNumber = "X"

  let value = 0

  const {isLoading, error, sendRequest} = useHttpClient();

  useEffect(() => { 
    const fetchData = async() => {
        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/profile`, "GET", null, {Authorization: 'Bearer '+auth.token});

            if(responseData){
              setCustomer(responseData.customers)
            }

        } catch (err) {
            console.log(error)
        }
    };
    fetchData();
}, [sendRequest, error, auth]); 

  const cardTopHandler = () => {
    setCardTopUp(!cardTopUp)
  }

  const withdrawHandler = () => {
    setWithdraw(!withdraw)
  }

  const walletInput = (actualValue) =>{
    value = actualValue
    localStorage.setItem("money", value)
  }
  return (
    <>
    <HOC >
      {cardTopUp && <CardTopUpModal show={cardTopUp} onCancel={cardTopHandler}/>}
      {withdraw && <WithdrawTopUpModal show={withdraw} onCancel={withdrawHandler}/>}
      <div className='grid grid-cols-1 lg:grid-cols-5 gap-4 md:gap-8'>

        <div className=' col-span-3 flex flex-col rounded justify-center items-center bg-wallet-background p-4 md:p-6'>
          <h3 className='font-gilroy text-sm md:text-xl text-white'>Wallet Balance </h3>
          {!isLoading && customer && (
            <div className='flex justify-start items-center mt-4  '>
              {eye ? <Number className=' text-white text-lg md:text-2xl font-gilroy-medium ' text value={customer.balance} onInput={walletInput}/> : 
              <h3 className=' text-white text-2xl font-gilroy-medium '>{Array(customer.balance.toString().length +1).join(xNumber)+".XX"}</h3>}
              {eye ? <IconEye.BsEye className='text-white ml-6 text-2xl font-gilroy-semibold cursor-pointer' onClick={
                () => setEye(!eye)
              }/> : <IconEye.BsEyeSlash className='text-white ml-6 text-2xl font-gilroy-semibold cursor-pointer' onClick={
                () => setEye(!eye)
              }/>}
            
          </div>
          )}
          

        </div>


        <div className='bg-white px-4 md:px-6  py-4 rounded col-span-3 lg:col-span-2'>
            <h4 className='mb-4'>Fund your wallet</h4>
            <Card image={images.cardTopUp[0]} alt={images.cardTopUp[1]} title="Card top up" className="mb-4" handleButtonClick={cardTopHandler}/>
            <Card image={images.withdraw[0]} alt={images.withdraw[1]} title="Withdrawal" handleButtonClick={withdrawHandler}/>
        </div>

          
        
      </div>

      <div className='bg-white p-4 mt-6'>
        <h3 className='text-link-color font-gilroy-bold text-lg mb-2'>Transaction history</h3>
        <WalletTransactionTable/>
      </div>

    </HOC>

    </>
  )
}

export default Wallet