import React, { useContext, useEffect, useState } from 'react'

import * as Icons from "react-icons/io";
import ReactDOM from 'react-dom';


import { CSSTransition } from 'react-transition-group';
import { ToastContainer } from 'react-toastify'

import { DataContext } from '../../context/DataContext';


import Number from '../../../UI_Elements/Input/Number';
import Backdrop from '../../../UI_Elements/Backdrop/Backdrop';
import Withdraw from './components/Withdraw';


function WithdrawTopUpModalOverlay(props) {
    const user_data  = useContext(DataContext)
    const [mainPage, setMainPage] = useState(true)

    const [withdrawShow, setWithdrawShow] = useState(false)

    const [amountInput, setAmountInput] = useState(null)
    
    const numberInput = (value) => {
      setAmountInput(value)
    }

    useEffect(() => {
      setMainPage(true)
      setWithdrawShow(false)
    }, [])

    let config ={
      amount: amountInput,
      customer: {
        email: user_data.email,
        phonenumber: user_data.phonenumber,
        name: `${user_data.firstname} ${user_data.lastname}`,
      }
    };
    

    const amountHandler = async() => {
      setWithdrawShow(!withdrawShow)
      setMainPage(!mainPage)
  }

    const content = (
        <div className='p-3 py-8 md:p-8 lg:pb-16 modal'>
          <ToastContainer colored/>
            {withdrawShow && <Withdraw  config={config}/>}
           {mainPage && (
            <>
              <h3 className='text-link-color text-lg font-gilroy-semibold'>Bank Withdrawal</h3>

              <div className='flex justify-center items-center flex-col mt-10 '>
                <Number className="font-gilroy-bold text-center w-full text-link-color text-2xl lg:text-2xl" onInput={numberInput}/>
              </div>

              <div className='w-full mt-16 flex justify-center'>
              <button className='bg-primary-purple text-white py-3 w-3/5 rounded-md ' onClick={amountHandler}>
                  Continue
              </button>
              </div>
            </>
           )}
            
            <div className='absolute top-4 right-4'>
              <button onClick={props.onCancel} className="rounded-full cursor-pointer p-1 md:p-2 bg-border-color">
                  <Icons.IoIosClose className='text-lg text-primary-black pointer' />
              </button>
            </div>
        </div>
    )

    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const WithdrawTopUpModal = props => {
    return (
      <React.Fragment>
        {props.show && <Backdrop onClick={props.onCancel} />}
        <CSSTransition
          in={props.show}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames="modal"
        >
          <WithdrawTopUpModalOverlay {...props} />
        </CSSTransition>
      </React.Fragment>
    );
  };


export default WithdrawTopUpModal
