import React from 'react'

import * as Icons from 'react-icons/ri'

function TablePagination(props) {
  return (
    <div className='flex justify-center items-center whitespace-nowrap w-full mt-8 pl-4 md:pl-0'>
                    
                <div className="w-full justify-between items-center flex">
                <span className=' text-primary-purple text-sm font-gilroy-bold'>
                        Showing{' '}
                        <span className='mr-2'>
                            {props.current_page}
                            {' '} to {props.last_page}
                            {' '} of {props.last_page} entries
                        </span>{' '}
                    </span>
                    {' '}
                    <div className='justify-end bg-light-purple items-center flex '>
                        <button className={`${props.prev_page_url !== null ? 'bg-primary-purple' : 'bg-light-purple'} p-1 px-2 inline-flex justify-center items-center`} onClick={() => props.pageChangeHandler(props.prev_page_url)} >
                            <Icons.RiArrowDropLeftLine className={`${props.prev_page_url !== null ? 'text-white' : 'text-table-gray'} text-xl font-medium`}/>
                            <p className={`${props.prev_page_url !== null ? 'text-white' : 'text-table-gray'} mr-1 text-sm`}>Prev</p>
                            
                        </button>{' '}

                        {
                            props.pages.map((option, index) => {
                                if(option.label === "&laquo; Previous" || option.label === "Next &raquo;"){
                                    return (
                                        <div key={index}>{""}</div>
                                    )
                                }
                                return(
                                    <React.Fragment key={index}>
                                    <button  className={`${option.active === true ? 'bg-primary-purple' : 'bg-table-gray'} p-1 px-3 text-white inline-flex justify-center items-center`} onClick={() => props.pageChangeHandler(option.url)}>
                                        {option.label}
                                    </button>{' '}
                                    </React.Fragment>
                                )
                            })
                        }
                        
                        <button className={`${props.next_page_url !== null ? 'bg-primary-purple' : 'bg-light-purple'} p-1 px-2 inline-flex justify-center items-center`} onClick={() => props.pageChangeHandler(props.next_page_url)} >
                            <p className={`${props.next_page_url !== null ? 'text-white' : 'text-table-gray'} mr-1 text-sm`}>Next</p>
                            <Icons.RiArrowDropRightLine className={`${props.next_page_url !== null ? 'text-white' : 'text-table-gray'} text-xl font-medium`}/>
                        </button>    
                    </div>{' '}

                    
                </div>
            </div>
  )
}

export default TablePagination