import React from 'react'

// import * as Icons from "react-icons/bs";

// custom hooks
import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';
// import {AuthContext} from '../context/auth-context';

//custom utils
import { VALIDATOR_REQUIRE} from '../util/validators'
import { Link, useNavigate } from 'react-router-dom';

//custom components
import Input from '../UI_Elements/Input/Input'
import { images } from '../assets/images';

import './auth.css'

const Referral = () => {

    const history = useNavigate()

    const {isLoading} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        referral: {
            value: '',
            isValid: false
        }
    }, false);


    const authSubmitHandler = async event => {
        event.preventDefault();
        

        return history(`/plan-page?referral=${formState.inputs.referral.value}`)
    };


  return (
    <>
      <div className="relative min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8 background__auth">
        <div className="max-w-xl relative md:max-w-5xl w-full space-y-8">
        
          <div>
            <a href='https://myadvantage.africa/'>
              <img
                src={images.logo[0]}
                alt={`Logo`}
                className="mx-auto h-20 w-auto"
              />
            </a>

            <div className='grid grid-cols-1 md:grid-cols-6'>
                <div className='flex items-center mr-6'>
                  <a href='https://myadvantage.africa/'  className='flex items-center mr-6'>
                    <img src={images.webBack[0]} alt={images.webBack[1]}/>
                  </a>
                    
                </div>
                <h2 className="mt-6 text-center md:col-span-4 text-4xl lg:text-5xl font-gilroy-extra-bold text-primary-orange-title">
                  Did someone refer you
                </h2>
                <div></div>
            </div>
            
            
          </div>
          <form className="mt-8 space-y-6 form_login " onSubmit={authSubmitHandler} >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
                <p className='text-sm text-subtitle-black font-gilroy-medium mb-8'>Please type in the referral code of the person that sent you the link</p>
                <Input
                    type="text"
                    placeholder='Enter code'
                    label="Referral code"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="referral"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Referral not valid"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    className={`appearance-none ${formState.inputs.referral.value && "bg-light-purple"} 
                    mb-4 rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 
                    placeholder-gray-500 form_login_input text-subtitle-black rounded-t-md focus:outline-none 
                    focus:ring-primary-purple focus:border-primary-purple  focus:z-10 sm:text-sm`}/>
                
              
            </div>

            <div className='flex justify-start items-center'>
              <button
                type="submit"
                className="group relative flex items-center justify-around py-3 px-12 border border-transparent text-base font-gilroy-semibold rounded-md text-white bg-primary-purple hover:bg-primary-purple focus:outline-none "
              >
                {isLoading ? 'Processing...' : 'Next'}
              </button>

              <Link
                to="/plan-page"
                className="group ml-4 relative flex items-center justify-around py-3 px-12 border border-transparent text-base font-gilroy-semibold rounded-md text-white bg-primary-purple hover:bg-primary-purple focus:outline-none "
              >
                {'Skip'}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


export default Referral