import React, {useMemo, useEffect, useState} from 'react'
// import { useNavigate } from 'react-router-dom';
// REACT HOOKS
import {useTable, usePagination} from 'react-table'


// DATA
import {TREE_COLUMNS} from '../../../util/columns/columns';


import './tables.css'





function TreeTable(props) {   
    

    var special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

    function stringifyNumber(n) {
        if (n < 20) return special[n];
        if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
        return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
    }

    const [cellData , setCellData] = useState([]);

    useEffect(() => {
        setCellData([])
        if(props.data){
            
            for(let item of props.data){
                setCellData((cell)=>[...cell, {
                    "name": `${item.firstname} ${item.lastname}`,
                    "referral_code": `${item.referral_code}`,
                    "rank": `${item.customerlevel}`,
                    "downlinerscount": `${item.downlinerscount}`

                }])
            }
        }
        
    }, [props.data]);


 

    const columns = useMemo(() => TREE_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
          
    }, usePagination)


    return (
        <>
            {
                props.data && props.data.length !==0 && (
            <div className="bg-white pb-8 p-4 shadow-md sm:rounded-lg mt-6 overflow-x-auto w-full">

            <div className='mt-4'>
                <h1 className='font-bold px-2 mb-2 '> <span className='capitalize'>{props.level === "1" ? "Direct" : stringifyNumber(props.level)}</span> Downlines</h1>
                <p className='mb-4 px-2'>These are your <span className='capitalize'>{stringifyNumber(props.level)}</span> level downlines</p>
            </div>
            
    
            <table {...getTableProps()} className="w-full text-left">
                <thead className='box-b-1'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} >
                            <th className="text-xs whitespace-nowrap px-4 py-2">SN</th>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap px-4 py-2">{column.render('Header')}</th>
                                ))}
                            
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row,index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>
                                <td className="text-sm px-4 py-4 whitespace-nowrap font-gilroy-medium">{index+1}</td>
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-4 whitespace-nowrap">{cell.render('Cell')}</td>
                                    })}
                                
                            </tr>
                        )
                    })}
                </tbody>


            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            
            
        </div>
                )
            }
        </>              
    )
}

export default TreeTable
