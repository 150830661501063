import React, { useContext } from 'react'
import { images } from '../../assets/images'
import { copyToClipBoard } from '../../util/copyToClipboard'
import { DataContext } from '../context/DataContext'
import HOC from '../hoc/HOC'
import * as IconSocial from "react-icons/fa"
import * as IconSocials from "react-icons/ri"


function Referral() {
  const data = useContext(DataContext)
  data.updatePage("Referral")
  return (
    <HOC bgwhite>
      <div className='grid grid-cols-1 lg:grid-cols-3 py-6'>
          <div className='col-span-2'>
            <h2 className='text-lg  font-gilroy-semibold'>Refer new members and start enjoying lots of benefits</h2>

            <div className='mt-12'>
                <h3 className='font-gilroy-medium text-primary-black '>Referral Code</h3>

                <div className='flex items-center '>
                    <h1 className='text-primary-purple text-2xl font-gilroy-medium mr-8'>{data.referralCode}</h1>
                    <img src={images.copy[0]} alt={images.copy[1]} className="w-4 cursor-pointer" onClick={
                      () =>{
                        copyToClipBoard(data.referralCode.toString())
                      }
                    }/>
                </div>
            </div>

            <div className='flex justify-between items-end mt-6'>
                <h3 className='font-gilroy-medium text-primary-black '>Referral Link</h3>
                
            </div>

            <div className='bg-gray-100 rounded grid grid-cols-3 mt-8'>
                <p
                    className='col-span-2 py-1 px-1 break-words text-primary-purple font-gilroy-bold my-auto text-sm'>https://customer.myadvantage.africa/plan-page?referral={data.referralCode}</p>
                <button
                    className='bg-primary-purple text-white px-2 py-4 rounded ml-auto text-sm xl:text-base' onClick={
                      ()=>{
                        copyToClipBoard(`https://customer.myadvantage.africa/plan-page?referral=${data.referralCode}`)
                      }
                    }>Copy link</button>
            </div>

            <div className='flex justify-start items-center mt-4'>
                    <a href='https://www.facebook.com/myAdvantageAfrica' target="__blank">
                        <IconSocial.FaFacebook
                        className='w-10 h-10 p-2 bg-light-purple text-facebook-blue  '/>
                    </a>

                    <a 
                    href='https://api.whatsapp.com/send?phone=2349139376140&text=Hi+myAdvantage+Team.+I%27m+interested+in+joining+your+platform.+I+need+to+know+more+about+your+plans' 
                    target="__blank">
                        <IconSocials.RiWhatsappFill
                        className='w-10 h-10 p-2 bg-light-purple text-primary-green  ml-2'/>
                    </a>
                </div>

            </div>


          <img src={images.referralImage[0]} alt={images.referralImage[1]} className="hidden lg:block"/>
      </div>
    </HOC>
  )
}

export default Referral