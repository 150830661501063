import React from 'react'
import { images } from '../../../assets/images'


function Card(props) {
  return (
    <button className={`bg-light-purple w-full p-4 md:px-6 xl:px-8 flex justify-between items-center rounded ${props.className}`} onClick={props.handleButtonClick}>
       <div className='flex items-center'>
            <img src={props.image} alt={props.alt} className="w-8 h-8 "/>
            <h4 className='text-primary-purple ml-4 font-gilroy-semibold text-lg'>{props.title}</h4>
       </div>

       <div>
           <img src={images.rightIcon[0]} alt={images.rightIcon[1]} className="w-2 h-4"/>
       </div>
    </button>
  )
}

export default Card