import React, { useContext } from 'react';
import { images } from '../../../assets/images';
import { DataContext } from '../../context/DataContext';




function Avatar(props) {

  const data = useContext(DataContext)
  return (
      <>
                <img
                    src={data.image || images.profileBlank[0]}
                    alt={props.alt || images.profileBlank[1]}
                    className={` ${props.className}`}/>
                    
      </>
  )
}

export default Avatar;
