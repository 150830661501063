

export const TRANSACTIONS_COLUMNS = [
  {
    Header: 'Transaction type',
    accessor: 'transaction_type',
    
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    
  },
  {
    Header: 'Transaction ID',
    accessor: 'transaction_id',
    
  },
  {
    Header: 'Date',
    accessor: 'date',
    
  },
  {
    Header: 'Status',
    accessor: 'status'
  }
]



export const MEMBERS_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    
  },
  {
    Header: 'Sponsor',
    accessor: 'sponsor',
    
  },
  {
    Header: 'Date added',
    accessor: 'date',
    
  },
  {
    Header: 'Current Rank',
    accessor: 'current'
  },
  {
    Header: 'Next Rank',
    accessor: 'next'
  }
]


export const WALLET_TRANSACTIONS_COLUMNS = [
  {
    Header: 'transaction type',
    accessor: 'transaction_type',
    
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    
  },
  {
    Header: 'Transaction ID',
    accessor: 'transaction_id',
    
  },
  {
    Header: 'Date',
    accessor: 'date',
    
  },
  {
    Header: 'Status',
    accessor: 'status'
  }
]


export const PAYMENTS_COLUMNS = [
  {
    Header: 'Payment plan',
    accessor: 'plan',
    
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    
  },
  {
    Header: 'Date',
    accessor: 'date',
    
  },
  {
    Header: 'Next Payment Date',
    accessor: 'next_date',
    
  }
]

export const INVOICE_COLUMNS = [

  {
    Header: 'Total amount',
    accessor: 'total_amount',
    
  },
  {
    Header: 'Paid amount',
    accessor: 'paid_amount',
    
  },
  {
    Header: 'Date',
    accessor: 'date',
    
  }
]


export const CLAIMS_COLUMNS = [
  {
    Header: 'Type',
    accessor: 'claim_type',
    
  },
  {
    Header: 'Date claimed',
    accessor: 'date',
    
  },
  {
    Header: 'Claim Name',
    accessor: 'claim_name',
    
  },
  {
    Header: 'Details',
    accessor: 'details',
    
  },
  {
    Header: 'Vendor Link',
    accessor:'vendor_link'
  }
]

export const REWARDS_COLUMNS = [
  {
    Header: 'Reward Name',
    accessor: 'reward_name',
    
  },
  {
    Header: 'Achieved Date',
    accessor: 'date',
    
  },
  {
    Header: 'Details',
    accessor: 'details',
    
  },
  {
    Header: 'Vendor Link',
    accessor:'vendor_link'
  }
]

export const TREE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    
  },
  {
    Header: 'Referral code',
    accessor: 'referral_code',
    
  },
  {
    Header: 'Rank',
    accessor: 'rank',
    
  },
  {
    Header: 'Downliners',
    accessor:'downlinerscount'
  }
]

export const DASHBOARD_TREE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    
  },
  {
    Header: 'Referral code',
    accessor: 'referral_code',
    
  },
  {
    Header: 'Current rank',
    accessor: 'rank',
    
  },
  {
    Header: 'Downliners',
    accessor:'downlinerscount'
  }
]