import React, { useContext, useState } from 'react'

import HOC from '../hoc/HOC'

import PaymentTable from '../components/Tables/PaymentTable'
import InvoiceTable from '../components/Tables/InvoiceTable';
import { DataContext } from '../context/DataContext';

function Payments() {
  const [invoice, setInvoice] = useState(false);
  const [payment, setPayment] = useState(true);

  const data = useContext(DataContext)
  data.updatePage("Payments & Invoices")

  const invoiceHandler = () =>{
    if(invoice){
      return
    }

    setInvoice(!invoice)
    setPayment(!payment)

  }

  const paymentHandler = () =>{
    if(payment){
      return
    }

    setPayment(!payment)
    setInvoice(!invoice)
  }
  return (
    <HOC >
      <div className='bg-white rounded-md text-sm font-gilroy-medium flex justify-start items-center p-1 w-fit'>
        <button 
        className={` p-2 px-3 hover:bg-primary-black ${!invoice ? "bg-primary-black text-white" : "text-primary-black"} 
        hover:text-white rounded-l-md `} 
        onClick={paymentHandler}>
          My payments
        </button>
        <button className={` p-2 px-3 hover:bg-primary-black ${invoice ? "bg-primary-black text-white" : "text-primary-black"}  
        hover:text-white rounded-r-md `} 
        onClick={invoiceHandler}>
          My Invoice
        </button>
      </div>

      <div>
        {payment && <PaymentTable/>}
        {invoice && <InvoiceTable/>}
      </div>
    </HOC>
  )
}

export default Payments