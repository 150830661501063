import React from 'react'
import '../auth.css'

import {IoIosCheckmarkCircle} from 'react-icons/io'

function PlanCard(props) {
  return (
    <div className={`flex  flex-col justify-center card-padding ${props.className}`}>
        <h3 className='text-primary-purple font-gilroy-semibold text-20 mb-6'>{props.title || ""}</h3>
        <div className='mb-4'>
            <h2 className='text-primary-black font-extra-bold font-extra  text-32'>
                ₦{`${props.amount || 0}/`}
                <span className='font-gilroy  text-primary-black text-base'>
                    {` ${props.period || ""}`}
                </span>
            </h2>
        </div>

        <p className='text-primary-black mb-8 text-xs font-gilroy-bold'  >{props.text || ''}</p>

        {props.pay && (
            <button className='plan_page_button text-xs sm:text-base ' onClick={props.handler}>
                Get Started
            </button>
        )}

        <div className='my-4'>
            {props.claims && props.claims.map((item,index) =>{
                return(
                    <div className='flex items-center mb-3'>
                        <IoIosCheckmarkCircle 
                        className='text-2xl  text-primary-purple  mr-2 '/>
                        <p className='text-primary-black text-sm font-gilroy-medium'>{item.claim?.description || ""}</p>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default PlanCard