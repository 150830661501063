import React,{useContext, useEffect} from "react";

// import {images} from "../../assests/index";

import {Link} from 'react-router-dom'

import {AuthContext} from '../../context/auth-context';

// import Loader from "../../UI_Elements/loader/Loader";
import Avatar from '../../pages/UI_Elements/Avatar/Avatar'

import * as Icons from "react-icons/ti";

import { createPopper } from "@popperjs/core";
import { useHttpClient } from "../../hooks/http-hook";
import { images } from "../../assets/images";

const UserDropdown = (props) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popperDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popperDropdownRef.current, {
      placement: "bottom-end",
    });
    setDropdownPopoverShow(true);
    
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
    
  };

  const auth = useContext(AuthContext);

  const {error, sendRequest} = useHttpClient();

  useEffect(() => {

    // Hide on outside click  
  const hideOnClickOutside = (e) => {
    if( popperDropdownRef.current && !popperDropdownRef.current.contains(e.target) ) {
      setDropdownPopoverShow(false);
    }
  }
    
    document.addEventListener("click", hideOnClickOutside, true)
  }, [dropdownPopoverShow, popperDropdownRef] )

  

  const logoutHandler = async(event) =>{
    event.preventDefault();

    try {
      await
      sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/logout
      `, 'GET', null,{'Content-Type': 'application/json', Authorization: 'Bearer '+auth.token});

      auth.logout()
      

  } catch (err) {
      console.log(error)
  }

    
  }
  return (
    <div className="relative w-fit">
      <button
        className="text-slate-500 block"
        
      >
        <div className="items-center flex justiy-center bg-primary-border rounded-lg p-2 px-3" ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}>
          <span className="w-8 h-8 text-sm border-slate-300 border-2 text-white bg-white inline-flex items-center justify-center rounded-full">
            <Avatar src={props.image} alt="avatar" name={props.firstname} className={`w-full h-full rounded-full align-middle border-none shadow-lg`} profileClass="w-full h-full rounded-full align-middle border-none shadow-lg  text-primary-red" red textCenter/> 
          </span>
          <span className="mx-1 font-medium">{props.firstname}</span>
          <Icons.TiArrowSortedDown className='ml-1 text-base rounded-md text-primary-black' />
        </div>

      </button>
      <div
        ref={popperDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          " bg-white text-base z-50 py-2 list-none text-left rounded shadow-lg min-w-48 overflow-hidden"
        }
      >

      <Link
          to="./profile"
          className={
            `flex items-center justify-start text-sm py-2 
            mx-2 px-2 md:px-4 text-left font-normal w-full border-b 
            border-border-color hover:shadow whitespace-nowrap 
            bg-transparent text-primary-black`
          } 
        >
          <img src={images.sEight[0]} alt={images.sEight[1]} className="mr-2"/>
          Profile
        </Link>

        <button
          
          className={
            `flex items-center justify-start text-sm py-2 mx-2 px-2 md:px-3 
            hover:shadow text-left font-normal w-full whitespace-nowrap 
            bg-transparent min-w-48`
          }
          onClick={logoutHandler}
        >
          <img src={images.powerButton[0]} alt={images.powerButton[1]} className="mr-2"/>
          Logout
        </button>
        
      </div>
    </div>
  );
};

export default UserDropdown;
