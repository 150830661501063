import React, {useEffect} from 'react';

import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import AOS from "aos";
import { ToastContainer } from 'react-toastify';

import "aos/dist/aos.css";
import 'react-toastify/dist/ReactToastify.css';
import './App.css'

//Dashboard components
import Dashboard from './pages/Dashboard';


// Auth components
import Signup from './auth/Signup';
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import PaymentPlan  from './auth/PaymentPlan';
import PaymentPage  from './auth/PaymentPage';
import Referral  from './auth/Referral';


import { AuthContext } from './context/auth-context';
import { useAuth } from './hooks/auth-hook';

function App() {

    useEffect(() => {
        AOS.init({duration: 2000});
        AOS.refresh();
        
    }, []);

    const { token, login, logout } = useAuth();

    let routes;

    if (token) {
        
        routes = (
            <Routes>
                <Route path='/dashboard/*' element={<Dashboard/>} />
                <Route path="*" element={<Navigate to ="/dashboard" />}/>
                <Route path='/plan-page' exact element={< PaymentPlan />}/>
                <Route path='/payment-page' exact element={< PaymentPage />}/>
            </Routes>
        );
    } else {
        routes = (
            <Routes>
                <Route path='/registration' exact element={< Signup />}/>
                <Route path='/login' exact element={< Login />}/>
                <Route path='/plan-page' exact element={< PaymentPlan />}/>
                <Route path='/referral' exact element={< Referral />}/>
                <Route path='/payment-page' exact element={< PaymentPage />}/>
                <Route path='/resetpassword' exact element={< ResetPassword />}/>
                <Route path="*" element={<Navigate to ="/login" />}/>
            </Routes>
        );
    }

    return (
        <AuthContext.Provider
            value={{
            isLoggedIn: !!token,
            token:token,
            login: login,
            logout: logout
        }}>
          <ToastContainer colored/>
            <Router>

                {routes}

            </Router>
        </AuthContext.Provider>
    );
}

export default App;