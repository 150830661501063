import React, { useContext, useEffect, useState } from 'react'
import HOC from '../hoc/HOC'

import TreeTable from '../components/Tables/TreeTable'
import { useHttpClient } from '../../hooks/less-http-hook';
import { AuthContext } from '../../context/auth-context';
import Loader from '../../UI_Elements/loader/Loader';
import { DataContext } from '../context/DataContext';
import EmptyTransactions from '../components/Placeholders/EmptyTransactions';
import { images } from '../../assets/images';


function Tree() {

  const [listData , setListData] = useState([]);

  const {isLoading, error, sendRequest} = useHttpClient();

  const auth = useContext(AuthContext)
  const data = useContext(DataContext)

  data.updatePage("Tree genealogy")

  useEffect(() => {

    const fetchPolicies = async() => {
        
        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/list/downliners`, "GET", null, {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});

            if(responseData){
              setListData(responseData.data)
            }

        } 
        catch(err) {
            console.log(error)
        }
    };
    fetchPolicies();

}, [sendRequest, error, auth]);

  return (
    <HOC >
      <div className="relative w-3/5 mb-4">
            <input 
            type="search" 
            id="location-search" 
            className="block p-3 px-4 w-full z-20 text-sm text-primary-black bg-white rounded-r-lg placeholder:text-border-color" 
            placeholder="Search member name" required/>
            <button type="submit" className="absolute top-0 right-0 p-3 px-6 text-sm font-medium text-white bg-primary-purple rounded-r-lg focus:outline-none">
                Search
            </button>
        </div>

      {listData.length === 0 && <EmptyTransactions src={images.emptyOne[0]} alt={images.emptyTwo[1]} text="Start referring to view downliners" />}
      {isLoading ? <Loader/> : (
        <div className=''>
          {listData.length !== 0 && listData.map((data,index) => {
              return (
                <div key={index}> 
                  <TreeTable level={data.level} data={data.data} />
                </div>
              )
            })}


        </div>
      )}
      
    </HOC>

  )
}

export default Tree