import React, {useMemo, useEffect, useState, useContext} from 'react'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';


import {AuthContext} from '../../../context/auth-context';
import { useHttpClient} from '../../../hooks/less-http-hook'
import { usePdfClient } from '../../../hooks/pdf-http-hook';


// DATA
import {INVOICE_COLUMNS} from '../../../util/columns/columns';
import TablePagination from '../../UI_Elements/TablePagination/TablePagination';

import Loader from '../../../UI_Elements/loader/Loader';

import './tables.css'



function InvoiceTable() {    

    const auth = useContext(AuthContext)

    const {sendPdfRequest} = usePdfClient()

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"15",
        "status":"0",
        "search_text":""
    })

    const [pages, setPages] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);

        const data = JSON.stringify({
            "page_number": detailsData.page_number,
            "status": "1"
        })

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/payments/list`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
                console.log(responseData)
                setTableData(responseData.payments);
                setPages(responseData.payments.links)
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.payments.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.id,
                            "date": moment(file.created_at).format('l'),
                            "paid_amount": file.amount,
                            "total_amount": file.plan.plan_amount,
                            "amount": file.amount,
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, detailsData]);


    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const singleInvoice = async(e,id) =>{
        
        try{
            const responseData = await sendPdfRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/payments/invoice?id=${id}`, "GET", null , {Authorization: 'Bearer '+auth.token, Accept: "application/*"});
            window.open(URL.createObjectURL(responseData), '_blank')
            
            
        }
        catch(err){
            console.log(error)
        }
    } 

    const columns = useMemo(() => INVOICE_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>
        
        {isLoading ? <Loader/> :
        
        <div className="bg-white pb-8 p-0 md:p-4 shadow-md sm:rounded-lg mt-6 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='box-b-1'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} >
                            <th className="text-xs whitespace-nowrap py-2 px-4 ">SN</th>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap py-2 px-4">{column.render('Header')}</th>
                                ))}
                            <th className="text-xs whitespace-nowrap py-2 px-4 ">{'Action'}</th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} className='box-b-1'>
                                <td className="text-sm py-4 px-4 whitespace-nowrap font-gilroy-medium">{index+1}</td>
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-4 whitespace-nowrap font-gilroy-medium">{cell.render('Cell')}</td>
                                    })}
                                <td className='text-sm py-4 px-4 whitespace-nowrap font-gilroy-medium' onClick={(e) => singleInvoice(e,row.original.id)}>
                                    <p 
                                    className='text-primary-black cursor-pointer '
                                    >Download</p>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>


            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}

            
            
        </div>
        }
        </>              
    )
}

export default InvoiceTable
