import React, {useContext, useEffect, useMemo, useState} from 'react'

// REACT HOOKS
import {useTable} from 'react-table'
import moment from 'moment';

import * as Icons from 'react-icons/cg'

import { useHttpClient } from '../../../hooks/less-http-hook'
import {AuthContext} from '../../../context/auth-context';


// DATA
import {TRANSACTIONS_COLUMNS} from '../../../util/columns/columns';
import EmptyTransactions from '../Placeholders/EmptyTransactions';
import { images } from '../../../assets/images';
import Loader from '../../../UI_Elements/loader/Loader';

import './tables.css'



function TransactionTable() {    

    const auth = useContext(AuthContext)


    const [tableData, setTableData] = useState(null);

    const [cellData , setCellData] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)
        

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/wallet/transactions?status=0&page_number=10`, "GET", null , {Authorization: 'Bearer '+auth.token});
                setTableData(responseData.payments)
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.payments.data){
                        setCellData((cell)=>[...cell, {
                            "transaction_type":file.type,
                            "amount": file.amount,
                            "date": moment(file.created_at).format('lll'),
                            "transaction_id": file.reference,
                            "status": file.status,
                            
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth]);


    const columns = useMemo(() => TRANSACTIONS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    })
    
    return (
        <> 
        {isLoading ? <Loader/> : 
        
        <div className=" sm:rounded-lg mt-4 overflow-x-auto">
            

            {
                tableData === null ? <EmptyTransactions src={images.emptyTwo[0]} alt={images.emptyTwo[1]} text="No transactions" /> : 
                <table {...getTableProps()} className="min-w-full text-left">
                    <thead className=''>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="table-head-bg px-2 space-x-1.5">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap p-2 py-4  ">{column.render('Header')}</th>
                                ))}
                            
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} className="p-2 hover:cursor-pointer hover:shadow-lg table-default-color hover:text-primary-black bg-white ">

                                {row
                                    .cells
                                    .map(cell => {
                                        if(cell.value === "debit"){
                                            return <td {...cell.getCellProps()} className="flex p-2 justify-start items-center font-gilroy-bold text-sm whitespace-nowrap">
                                                <div className="bg-primary-border p-2 flex justify-center py-2 items-center">
                                                    <Icons.CgArrowTopLeft className='text-primary-purple'/>
                                                    
                                                </div>
                                                <p className="text-sm  ml-2">Withdrawal</p>
                                            </td>
                                        }
                                        if(cell.value === "credit"){
                                            return <td {...cell.getCellProps()} className="flex p-2 justify-start items-center font-gilroy-bold text-sm whitespace-nowrap">
                                            <div className="bg-side-purple p-2 flex justify-center py-2 items-center">
                                                <Icons.CgArrowTopRight className='text-primary-purple'/> 
                                            </div>
                                            <p className="text-sm  ml-2">Top-up</p>
                                        </td>
                                        }
                                        if(cell.value === 0){
                                            return <td {...cell.getCellProps()} className="text-yellow font-gilroy-bold text-sm whitespace-nowrap">
                                                Pending
                                            </td>
                                        }
                                        if(cell.value === 1){
                                            return <td {...cell.getCellProps()} className="text-primary-green font-gilroy-bold text-sm whitespace-nowrap">
                                                Success
                                            </td>
                                        }
                                        if(cell.value === 2){
                                            return <td {...cell.getCellProps()} className="text-red font-gilroy-bold  text-sm whitespace-nowrap">
                                                Failed
                                            </td>
                                        }
                                        
                                        return <td {...cell.getCellProps()} className="text-sm whitespace-nowrap p-2 font-gilroy-bold  ">{cell.render('Cell')}</td>
                                    })}
                                
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            }
            <div className='w-full dash-h-1 bg-primary-black opacity-30'></div>
           
            
        </div>
}
        </>              
    )
}

export default TransactionTable
