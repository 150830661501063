import React, {useState, useEffect} from 'react';

import * as Icons from 'react-icons/ri'

import axios from 'axios';
import { toast } from 'react-toastify';


function DropboxUpload({
    onFiles,
    ...props
}) {

    const [dragEnter,
        setDragEnter] = useState(false);
    
    const [files,
            setFiles] = useState([]);

    const [urlFileList,
        setUrlFileList] = useState([]);

    
    const [imgs,setImgs] = useState([]);

    const [percent,setPercent] = useState(null);

    const [sent,setSent] = useState(true);


    const handleFiles = async (e) => {
        
        setFiles(e.target.files)
       
    };

    const handleDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    };
    const handleDragOver = (e) => { 
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    };
    const handleDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(false);
    };
    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        
        setFiles(e.dataTransfer.files)
       
    };

const UpdateImageUrls = useEffect(() =>{
        onFiles(urlFileList)
       
    },[onFiles, urlFileList])
   


    useEffect(() =>{

        const fileDisplay = () =>{
            if(files){
                
                
                for(let file of files){
                    // console.log(file.size)
                    if (file.size >= 50000000000 ){
                        
                        toast.error(`${file.name} is too large (${handleSize(file.size)})`, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });
                    }
                    const reader = new FileReader();
                    reader.onload = async (e) =>{
                        
                        setImgs((imgs) => [...imgs, e.target.result])
                        
                        try{
                            setSent(false)
                            const formData = new FormData();
                    
                            formData.append("file", file);
                            formData.append('upload_preset', 'leadfort-claims');
                    
                            const options = {
                                onUploadProgress: (progressEvent) => {
                                    const {loaded, total} = progressEvent;
                                    let percent = Math.floor( loaded * 100 / total);
                    
                                    if (percent < 100){
                                        setPercent(percent)
                                    }
                                }
                    
                    
                            }
                    
                            await axios.post("https://api.cloudinary.com/v1_1/devgodfrey/image/upload",formData, options).then(res =>{
                                
                                
                                
                                
                                setUrlFileList((urls => [...urls, res.data.secure_url]))
                                setSent(true)
                            return res.data.secure_url;
                            })
                        }catch(e){
                            console.log(e)
                        }
                        // onFiles(urlFiles)
                        
                        
                    };
                    
                    
                    reader.readAsDataURL(file)
                }


            }
        }

        fileDisplay()

        

            
    },[files, UpdateImageUrls])


    


    const handleSize = (size) =>{
        if(size <= 1000000){
            return (size/1000).toFixed(2) + 'KB';
        }
        if(size === 1000000 || size <= 1000000000){
            return (size/1000000).toFixed(2) + 'MB';
        }
        if(size === 1000000000 || size <= 1000000000000){
            return (size/1000000000).toFixed(2) + 'GB';
        }
    }

    const onClearImg = (index)=>{
        
        
        setImgs((imgs) => imgs.filter((_, i) => i !== index))

        setUrlFileList((imgs) => imgs.filter((_, i) => i !== index))
        
    }

    

    return (
        <div className={`${props.colSpan && 'md:col-span-2 '} ${props.className}`}>
            <h2 className='mb-2 text-base font-gilroy-bold '>{props.title}</h2>
            <div
                
                className={`relative z-0 py-4 sm:py-8 md:py-16 xl:py-20 px-2 mb-4 gap-4 w-full rounded-lg border-2 border-dashed flex ${imgs.length !== 0 && 'opacity-100'} ${dragEnter && 'border-primary-black opacity-50'}`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                accept={props.accept} >
                    {imgs.length === 0 && <div className='absolute top-0 left-0 h-full w-full text-primary-black flex flex-col justify-center items-center'>
                        <h3 className='text-sm md:text-lg opacity-80 text-center font-bold'>Drop your Images here</h3>
                        </div>}
                    {!sent && <div className='absolute top-0 left-0 h-full w-full bg-black opacity-60 text-white flex flex-col justify-center items-center'>
                        <h3 className='text-sm md:text-base text-center font-bold'>Uploading Images</h3>
                        <p className='text-xs md:text-sm text-center'>Please Kindly wait for images to be uploaded</p>
                        </div>}
                    <div className='z-10 flex flex-wrap align-items'>
                    {imgs.map((img,index) => (
                        <div className='relative w-32 h-32 m-2' key={index}>
                            <img src={img} alt={index} className={`w-32 h-32 object-cover ${percent<=99 ? "opacity-80" : 'opacity-100'}`} />
                            {
                                percent> 0 && sent === false && (
                                    <div className=" bg-gray-200 h-1 absolute top-2/3 left-2 w-10/12 rounded-full">
                                        <div className="bg-primary-red h-1 rounded-full" style={{width: `${percent}%`}}></div>
                                    </div>
                                )
                                
                            }
                            
                            
                            <Icons.RiCloseFill className="absolute top-1 right-1 text-sm text-primary-red bg-white rounded cursor-pointer" onClick={() => onClearImg(index)}/>
                        </div>
                        
                    ))}
                    </div>
                </div>
            <label htmlFor='Dropbox' className='text-primary-purple font-gilroy-bold text-sm cursor-pointer'>
                BROWSE <span className="dash__color">{props.browse}</span>
            </label>
            <input
                id="Dropbox"
                type="file"
                className='w-1 h-1 opacity-0 '
                accept={props.accept}    
                onChange={handleFiles}
                multiple/>
        </div>
    )
}

export default DropboxUpload;