import React, {useContext, useState} from 'react'
import {images} from '../../../../assets/images'
import {useForm} from '../../../../hooks/form-hook';
import {useHttpClient} from '../../../../hooks/http-hook';
import Inputs from '../../../../UI_Elements/Input/Inputs';
import { VALIDATOR_REQUIRE} from '../../../../util/validators'

import {useNavigate} from 'react-router-dom';

import {AuthContext} from '../../../../context/auth-context';

function Kyc(props) {
    const history = useNavigate();
    const {isLoading, error, sendRequest} = useHttpClient();

    const [referralCode, setReferralCode] = useState("")

    const auth = useContext(AuthContext);

    const [formState,
        inputHandler] = useForm({

        country: {
            value: '',
            isValid: false
        },
        residence: {
            value: '',
            isValid: false
        },
        home_address: {
            value: '',
            isValid: false
        },
        community_interest: {
            value: '',
            isValid: false
        },
        future_aspiration: {
            value: '',
            isValid: false
        },

        discount_preference: {
            value: '',
            isValid: false
        },
        health_condition: {
            value: '',
            isValid: false
        },
        health_condition_drugs: {
            value: '',
            isValid: false
        },

        allergy: {
            value: '',
            isValid: false
        },
        kin_name: {
            value: '',
            isValid: false
        },
        kin_phone_number: {
            value: '',
            isValid: false
        },

        vbank_account_number: {
            value: '',
            isValid: true
        },
        pharmacy_location: {
            value: '',
            isValid: false
        },
        pharmacy_name: {
            value: '',
            isValid: false
        }


    }, false);

    const authSubmitHandler = async event => {
        event.preventDefault();

        try {

            const data = JSON.stringify({
                "nationality" : formState.inputs.country.value,
                "state_of_residence" : formState.inputs.residence.value,
                "house_address" : formState.inputs.home_address.value,
                "upliner" : referralCode,
                "community_interest" : formState.inputs.community_interest.value,
                "future_aspiration" : formState.inputs.future_aspiration.value,
                "discount_preferences" : formState.inputs.discount_preference.value,
                "pre_existing_health_condtion": formState.inputs.health_condition.value,
                "pre_existing_health_condtion_drug" : formState.inputs.health_condition_drugs.value,
                "allergy" : formState.inputs.allergy.value,
                "next_of_kin_name" : formState.inputs.kin_name.value,
                "next_of_kin_phone_number" : formState.inputs.kin_phone_number.value,
                "vbank_account_number" : formState.inputs.vbank_account_number.value,
                "pharmacy_location": formState.inputs.pharmacy_location.value,
                "pharmacy_name": formState.inputs.pharmacy_name.value
            })

            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/kyc/create`, 'POST', data, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            });
            
            if(responseData){
                history('../dashboard')
                window.location.reload(false);
                props.kycChangeHandler("done")

            }

        } catch (err) {

            console.log(error)
        }
    };

    return (
        <div>
            
            <div className='flex justify-start items-center mb-6'>
                <img src={images.webBack[0]} alt={images.webBack[1]} className="mr-6 cursor-pointer" onClick={props.kycChangeHandler}/>
                <p className="text-base font-gilroy-bold text-primary-purple ">KYC Information</p>
            </div>


            <form onSubmit={authSubmitHandler}>
                {/* <Inputs
                    type="text"
                    placeholder='Enter your unique code'
                    label="Unique Code"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="unique_code"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid unique code"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={``}/> */}

                <Inputs
                    type="text"
                    placeholder='Enter your country of birth'
                    label="Nationality"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="country"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid country of birth"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}/>

                <Inputs
                    type="text"
                    placeholder='Enter your state of residence'
                    label="State of residence"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="residence"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid state of residence"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}/>

                <Inputs
                    type="text"
                    placeholder='Enter your address'
                    label="Home Address"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="home_address"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid home address"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}/>

                <div className="flex flex-col mb-4">
                    <label className={`text-xs mb-1 font-gilroy-semibold form_login_label`} >
                        Who invited you? (Referral code)
                    </label>

                    <input 
                    type="text"
                    placeholder='Enter code'
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 
                    border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md 
                    focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`} 
                    onInput={(e)=>{setReferralCode(e.target.value)}}/>
                </div>
                {/* <Inputs
                    type="text"
                    placeholder='Enter code'
                    label="Who invited you? (Referral code)"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="referral_code"
                    validators={[VALIDATOR_MINLENGTH(4)]}
                    isValid={true}
                    errorText="Please enter a valid referral code"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 
                    border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}/> */}

                <Inputs
                    
                    placeholder='Tell us your interest'
                    label="Community interest (Fitness, entrepreneurship, investment, saving
                        leadership)"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="community_interest"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter an interest"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}/>

                <Inputs
                    
                    placeholder='Tell us your future aspiration'
                    label="Future aspiration"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="future_aspiration"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid future aspiration"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-gray-300 placeholder-gray-500 form_login_input text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-black focus:border-primary-black focus:z-10 sm:text-sm`}/>

                <Inputs
                    
                    placeholder='Tell us your preference'
                    label="Discount Preferences (What do you spend money on the most that
                        you would like discounts on."
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="discount_preference"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid preference"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={``}/>

                <Inputs
                    
                    placeholder='Tell us your health conditions'
                    label="Pre-existing health condition"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="health_condition"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid health condition or type 'none'"
                    onInput={inputHandler}
                    containerClassName={`mb-4`}
                    className={``}/>

                <Inputs
                    
                    placeholder='Tell us the drugs you use'
                    label="Pre-existing health condition drugs"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="health_condition_drugs"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid health condition drugs or type 'none'"
                    onInput={inputHandler}
                    containerClassName={`mb-4`}
                    className={``}/>

                <Inputs

                    placeholder='Tell us your allegies and drugs used'
                    label="Allergy?"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="allergy"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid health concition or type 'none'"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={``}/>

                <Inputs
                    type="text"
                    placeholder='Enter name'
                    label="Next of Kin name"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="kin_name"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid next of kin name"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={``}/>

                <Inputs
                    type="text"
                    placeholder='Enter phone number'
                    label="Next of Kin phone number"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="kin_phone_number"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid kin phone number"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={``}/>

                <Inputs
                    type="text"
                    placeholder='Enter pharmacy address close to you'
                    label="Address of pharmacy location close to you?"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="pharmacy_location"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid location"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={``}/>

                <Inputs
                    type="text"
                    placeholder='Enter name of pharmacy'
                    label="Name of Pharmacy"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="pharmacy_name"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid pharmacy name"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={``}/>

                
                {/* <Inputs
                    type="text"
                    placeholder='Enter account number'
                    label="Vbank Account Number"
                    labelClassName={`text-xs mb-1 font-gilroy-semibold form_login_label`}
                    id="vbank_account_number"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid Vbank Account Number"
                    onInput={inputHandler}
                    containerClassName={``}
                    className={``}/> */}

                

                
                

                

                <div>
                    <button
                        type="submit"
                        disabled={!formState.isValid}
                        className={`group relative  w-full flex items-center justify-around py-2 px-12 border border-transparent text-base font-gilroy-extra-bold rounded-md text-white ${formState.isValid
                        ? 'bg-primary-purple hover:bg-primary-purple '
                        : 'bg-slate-500 cursor-not-allowed'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-purple`}>
                        {isLoading
                            ? 'Processing...'
                            : 'Next'}
                    </button>
                </div>

            </form>
        </div>
    )
}

export default Kyc