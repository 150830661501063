import React from 'react'

function HOC(props) {
  return (
    <div className={`${props.className} py-2 px-2 sm:px-2 md:px-4 ${props.bgwhite && "bg-white"}`}>
        {props.children}
    </div>
  )
}

export default HOC