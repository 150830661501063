import React, { useEffect, useState } from 'react'

// import * as Icons from "react-icons/bs";

// custom hooks
import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';

//custom utils
import {VALIDATOR_EMAIL, VALIDATOR_MAXLENGTH, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE} from '../util/validators'
import { useNavigate, useSearchParams } from 'react-router-dom';
import './auth.css'

//custom components
import Input from '../UI_Elements/Input/Input'
import { images } from '../assets/images';


const Signup = () => {

    const history = useNavigate();

    const [searchParams] = useSearchParams();

    const [remember, setRemember] = useState(false)

    const [planId, setPlanId] = useState(null)

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({
          firstname: {
              value: '',
              isValid: false
          },
          lastname: {
            value: '',
            isValid: false
        },
          email: {
              value: '',
              isValid: false
          },
          password: {
              value: '',
              isValid: false
          },
          phone_number:{
            value: '',
            valid: false
          }
    }, false);

    useEffect(() => {
      setPlanId(searchParams.get('plan'));

  }, [searchParams])


    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {

            const data = JSON.stringify({
                plan_id: planId,
                firstname: formState.inputs.firstname.value,
                lastname: formState.inputs.lastname.value,
                phone_number: formState.inputs.phone_number.value,
                referral_code: searchParams.get("referral"),
                email: formState.inputs.email.value,
                password: formState.inputs.password.value,
                password_confirmation: formState.inputs.password.value,
            })

            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/register`, 'POST', data, {'Content-Type': 'application/json'});
            console.log(responseData)
            if(responseData){
              history(`/payment-page?token=${responseData.token}
            &reference=${responseData.reference}&id=${responseData.customers.customer_id}
            &planId=${planId}&email=${formState.inputs.email.value}&lastname=${formState.inputs.lastname.value}
            &firstname=${formState.inputs.firstname.value}&phonenumber=${formState.inputs.phone_number.value}`)
            }

        } catch (err) {    
            console.log(error)
        }
    };


  return (
    <>
      <div className="relative min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8 background__auth">
        
        <div className="max-w-xl relative md:max-w-5xl w-full space-y-8">
        
          <div>
          <a href='https://myadvantage.africa/'>
              <img
                src={images.logo[0]}
                alt={`Logo`}
                className="mx-auto h-20 w-auto"
              />
            </a>
            <div className='grid grid-cols-1 md:grid-cols-6'>
              <div className='flex items-center mr-6'>
              <img src={images.webBack[0]} alt={images.webBack[1]} onClick={() => {
                        history("/plan-page")
                }}/>
                  {/* <Icons.BsArrowLeftCircleFill  className="text-2xl text-slate-400 cursor-pointer" onClick={() => {
                        history("/plan-page")
                    }}/> */}
              </div>
              <h2 className="mt-6 text-center md:col-span-4 text-3xl lg:text-5xl font-gilroy-extra-bold text-primary-orange-title">Member details</h2>
              <div></div>
            </div>
            
          </div>  

          <form className="mt-8 space-y-6 form_login" onSubmit={authSubmitHandler} >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm">

            <Input
                type="text"
                placeholder='Your first name'
                label="First Name"
                required
                labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                id="firstname"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter your first name"
                onInput={inputHandler}
                containerClassName={`relative`}
                divClassName={`mb-4 `}
                errorClassName=""
                className={`appearance-none ${formState.inputs.firstname.value && "bg-light-purple"} rounded-none font-gilroy-medium relative block w-full px-3 py-3 border-4 border-gray-300
                  placeholder-table-gray form_login_input text-link-color rounded-t-md focus:outline-none 
                  focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple focus:z-10 sm:text-sm`}/>

            <Input
                type="text"
                placeholder='Your last name'
                label="Last Name"
                required
                labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                id="lastname"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter your last name"
                onInput={inputHandler}
                containerClassName={`relative`}
                divClassName={`mb-4 `}
                errorClassName=""
                className={`appearance-none rounded-none ${formState.inputs.lastname.value && "bg-light-purple"} font-gilroy-medium relative block w-full px-3 py-3 border-4 border-gray-300
                  placeholder-table-gray form_login_input text-link-color rounded-t-md focus:outline-none 
                  focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple focus:z-10 sm:text-sm`}/>

                <Input
                    type="email"
                    placeholder='Your email address'
                    label="Email Address"
                    required
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="email"
                    validators={[VALIDATOR_EMAIL()]}
                    errorText="Please enter a valid email"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    errorClassName="-mt-4 mb-2"
                    divClassName={`mb-4 `}
                    className={`appearance-none ${formState.inputs.email.value && "bg-light-purple"} rounded-none font-gilroy-medium relative block w-full px-3 py-3 border-4 border-gray-300
                  placeholder-table-gray form_login_input text-link-color rounded-t-md focus:outline-none 
                  focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple focus:z-10 sm:text-sm`}/>

                <Input
                    type="tel"
                    phone
                    placeholder='i.e 08012345678'
                    label="Phone number"
                    required
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="phone_number"
                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(11), VALIDATOR_MAXLENGTH(11)]}
                    errorText="Please enter a valid phone number"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    divClassName={`mb-4 `}
                    errorClassName="-mt-4 mb-2"
                    className={`appearance-none ${formState.inputs.phone_number.value && "bg-light-purple"} rounded-none font-gilroy-medium relative block w-full px-3 py-3 border-4 border-gray-300
                  placeholder-table-gray form_login_input text-link-color rounded-t-md focus:outline-none 
                  focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple focus:z-10 sm:text-sm`}/>


                <Input
                    type="password"
                    passwordValue
                    placeholder=''
                    label="Password"
                    required
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="password"
                    validators={[VALIDATOR_MINLENGTH(8)]}
                    errorText="Please enter more 8 characters"
                    onInput={inputHandler} 
                    containerClassName={`relative`}
                    divClassName={`mb-4 `}
                    errorClassName=" mb-2"
                    className={`appearance-none ${formState.inputs.password.value && "bg-light-purple"}  rounded-none font-gilroy-medium relative block w-full px-3 py-3 border-4 border-gray-300
                  placeholder-table-gray form_login_input text-link-color rounded-t-md focus:outline-none 
                  focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple focus:z-10 sm:text-sm`}
                    />
                
              
            </div>

            <div className="flex items-center justify-between">
            <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  onChange={() => setRemember(!remember)}
                  className="h-4 w-4 accent-orange-600 focus:ring-primary-purple border-primary-purple rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-primary-gray font-gilroy ">
                  I agree to myAdvantage’s <a href='https://res.cloudinary.com/devgodfrey/image/upload/v1670445741/Privacy-Policy.pdf' className='text-primary-purple' target="__blank" download>privacy policy</a>
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={remember && formState.isValid ? false : true}
                className={`group relative flex items-center justify-around py-3 px-14 border border-transparent 
                text-sm font-gilroy-semibold rounded-md text-white ${remember && formState.isValid ? 'bg-primary-purple hover:bg-primary-purple ' : 'bg-slate-500 cursor-not-allowed'}
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-purple`}
              >
                {isLoading ? 'Processing...' : 'Next'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


export default Signup