import React from 'react'

// import * as Icons from "react-icons/bs";

// custom hooks
import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';
// import {AuthContext} from '../context/auth-context';

//custom utils
import {VALIDATOR_EMAIL} from '../util/validators'
import { useNavigate } from 'react-router-dom';

//custom components
import Input from '../UI_Elements/Input/Input'
import { images } from '../assets/images';

const ResetPassword = () => {

    const history = useNavigate()

    // const auth = useContext(AuthContext);

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        }
    }, false);


    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {

            const data = JSON.stringify({
                email: formState.inputs.email.value
            })

            await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/forgotpassword`, 'POST', data, {'Content-Type': 'application/json'});

        } catch (err) {
            
            console.log(error)
        }
    };


  return (
    <>
      <div className="relative min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8 background__auth">
        <div className="max-w-xl relative md:max-w-5xl w-full space-y-8">
        
          <div>
            <a href='https://myadvantage.africa/'>
              <img
                src={images.logo[0]}
                alt={`Logo`}
                className="mx-auto h-20 w-auto"
              />
            </a>

            <div className='grid grid-cols-1 md:grid-cols-6'>
                <div className='flex items-center mr-6'>
                  <img src={images.webBack[0]} alt={images.webBack[1]} onClick={() => {
                        history("/login")
                    }}/>
                    {/* <Icons.BsArrowLeftCircleFill  className="text-2xl text-slate-400 cursor-pointer" onClick={() => {
                        history("/login")
                    }}/> */}
                </div>
                <h2 className="mt-6 text-center md:col-span-4 text-3xl lg:text-5xl font-gilroy-extra-bold text-primary-orange-title">
                  Reset Password
                </h2>
                <div></div>
            </div>
            
            
          </div>
          <form className="mt-8 space-y-6 form_login " onSubmit={authSubmitHandler} >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
                <p className='text-xs  mb-8'>Enter the email address you registered your account with and we’ll send an activation code</p>
                <Input
                    type="email"
                    placeholder='sampleemail@gmail.com'
                    label="Email Address"
                    labelClassName={`text-xs mb-1 font-gilroy-extra-bold form_login_label`}
                    id="email"
                    validators={[VALIDATOR_EMAIL()]}
                    errorText="Please enter a valid email"
                    onInput={inputHandler}
                    containerClassName={`relative`}
                    className={`appearance-none ${formState.inputs.email.value && "bg-light-purple"} mb-4 rounded-none 
                    relative block w-full px-3 py-2 border-4 border-gray-300 
                    placeholder-table-gray form_login_input text-gray-900 rounded-t-md 
                    focus:outline-none focus:ring-primary-purple focus:border-primary-purple focus:bg-light-purple  focus:z-10 sm:text-sm`}/>
                
              
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex items-center justify-around py-3 px-14 border border-transparent text-base font-gilroy-extra-bold rounded-md text-white bg-primary-purple hover:bg-primary-purple focus:outline-none "
              >
                {isLoading ? 'Processing...' : 'Proceed'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


export default ResetPassword