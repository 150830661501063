import React, { useContext, useState} from 'react'
import {useHttpClient} from '../../../../hooks/http-hook';


import {AuthContext} from '../../../../context/auth-context';
import UploadImage from './UploadImage';
import { images } from '../../../../assets/images';
import DocumentUpload from './DocumentUpload';

function Identity(props) {
    const {isLoading, error, sendRequest} = useHttpClient();
    
    const [id, setId] = useState('')

    const [passport, setPassport] = useState('')

    const auth = useContext(AuthContext);


    const authSubmitHandler = async event => {
        event.preventDefault();

        try {

            const data = JSON.stringify({type: '1', document_link: id.toString()})

            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/documents/create`, 'POST', data, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            });
            
            if(responseData){
                try {

                    const data = JSON.stringify({photo_url: passport.toString()})
        
                    const response = await
                    sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/upload/profile/img`, 'POST', data, {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + auth.token
                    });

                    if(response){
                        props.identityChangeHandler("done")
                    }
                    
                    
        
                } catch (err) {
        
                    console.log(error)
                }

                
            }

        } catch (err) {

            console.log(error)
        }
    };

    const passportHandler = (files) => {
        
        setPassport(files)

    }

    const idHandler = (files) => {
        setId(files)

    }

    return (
        <div>
            <div className='flex justify-start items-center mb-6'>
                <img src={images.webBack[0]} alt={images.webBack[1]} className="mr-6 cursor-pointer" onClick={props.identityChangeHandler}/>
                <p className="text-base font-gilroy-bold text-primary-purple ">Identity Verification</p>
            </div>
            

                <div className='my-6'>
                    <DocumentUpload 
                    title="Upload a valid ID (Government issued)"
                    accept="image/*, .doc, .docx, .txt, .pdf "
                    browse="(jpg, png, jpeg, pdf, doc, docx)"
                    onFiles={idHandler}/>
                </div>
                
                <div className='my-6'>
                <UploadImage 
                    title="Upload a passport photograph"
                    accept="image/*, .doc, .docx, .txt, .pdf "
                    browse="(jpg, png, jpeg, pdf, doc, docx)"
                    onFiles={passportHandler}/>
                </div>
                


                <div className='mt-6'>
                    <button
                        disabled={id !== null && passport !== null
                        ? false
                        : true}
                        className={`group relative  w-full flex items-center justify-around py-2 px-12 border border-transparent text-base font-gilroy-extra-bold rounded-md text-white ${id !== null && passport !== null
                        ? 'bg-primary-purple hover:bg-primary-purple '
                        : 'bg-slate-500 cursor-not-allowed'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-purple`} 
                        onClick={authSubmitHandler}>
                        {isLoading
                            ? 'Processing...'
                            : 'Next'}
                    </button>
                </div>

            
        </div>
    )
}

export default Identity