import React, { useContext, useEffect, useState } from 'react'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { AuthContext } from '../../../../context/auth-context';
import { useHttpClient } from '../../../../hooks/http-hook';
import Loader from '../../../../UI_Elements/loader/Loader';
import { images } from '../../../../assets/images';

function Flutterwave(props) {

    
    const auth = useContext(AuthContext);

    const {isLoading, error, sendRequest} = useHttpClient();

    const [success, setSuccess] = useState(false)

    const handleFlutterPayment = useFlutterwave({...props.config, tx_ref : props.txRef});
    
    useEffect( () => {
        setSuccess(false)
        
    }, [])

    const paymentHandler = async(response) => {
        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/wallet/verify?ref=${response.tx_ref.toString()}`, "GET", null, {'Content-Type': 'application/json',Authorization: 'Bearer '+auth.token});
            if(responseData){
              setSuccess(!success)
            }
        } catch (err) {
            console.log(error)
        }
    }

  return (
    <div className='flex justify-center items-center flex-col'>
        {isLoading && <Loader/>}
        {success ? (
            <div className={`flex flex-col justify-center items-center ${isLoading ? 'hidden' : 'block'}`}>
                <img src={images.success[0]} alt={images.success[1]} className="object-contain"/>
                <h3 className='text-primary-black text-lg font-gilroy-bold mt-2 mb-8'>Payment successful</h3>

                <p className='text-link-color'>{`You have successfully topped up your naira wallet with NGN${props.config.amount}`}</p>
            </div>
        ): (
            <button 
        className={`bg-primary-black px-6 py-2 text-white mx-auto rounded-sm hover:bg-primary-purple cursor-pointer ${isLoading ? 'hidden' : 'block'}`} 
        
        onClick={() => {
            handleFlutterPayment({
                callback: (response) => {
                    paymentHandler(response)
                    closePaymentModal() // this will close the modal programmatically
                },
                onClose: () => {},
            });
            }}>
            
            Pay with Flutterwave
        </button>
        )}
    </div>
  )
}

export default Flutterwave