import React, { useContext, useEffect, useState } from 'react'

import ReactDOM from 'react-dom';


import { CSSTransition } from 'react-transition-group';

import Backdrop from '../../../UI_Elements/Backdrop/Backdrop';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth-context';
import { useHttpClient } from '../../../hooks/less-http-hook';
import Loader from '../../../UI_Elements/loader/Loader';


function SubscribeModalOverlay(props) {
    const history = useNavigate()

    const auth = useContext(AuthContext)

    const [loadedData, setLoadedData] = useState(null)
    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/profile`, "GET", null, {Authorization: 'Bearer '+auth.token});
                
                setLoadedData(responseData);
                
    
            } catch (err) {
                console.log(error)
            }
        };
        fetchData();
    }, [sendRequest, error, auth]);
    const changeMembership = () =>{
        history(`/plan-page?token=${auth.token}&id=${loadedData.customers.customer_id}
                  &email=${loadedData.customers.email}&lastname=${loadedData.customers.lastname}
                  &firstname=${loadedData.customers.firstname}&phonenumber=${loadedData.customers.phone_number}`)
      }

    const content = (
        <>
        {isLoading ? <Loader/> :
        (
            <div className='p-3 py-8 md:p-8 lg:pb-16 modal'>
            
            <h1 className='text-center text-lg mb-6 font-gilroy-semibold'>Pro Membership</h1>

            <p className='text-center font-gilroy-medium mb-8'>The feature requires Pro membership subscription.
                You can subscribe right now if you’re ready to
                access the feature</p>
            
            <div className='flex justify-around items-center'>
                <button onClick={changeMembership} className="group relative flex items-center mx-auto md:mx-0 justify-around py-3 px-12 
                    text-sm font-gilroy-semibold rounded-md text-white 
                    bg-primary-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-purple">
                    Subscribe
                </button>

                <button onClick={props.onCancel} className="group relative flex items-center mx-auto md:mx-0 justify-around py-3 px-12 
                    text-sm font-gilroy-semibold rounded-md text-primary-purple 
                    bg-light-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-purple">
                    Cancel
                </button>
            </div>
        </div>
        )}
        </>
    )

    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const SubscribeModal = props => {
    return (
      <React.Fragment>
        {props.show && <Backdrop onClick={props.onCancel} />}
        <CSSTransition
          in={props.show}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames="modal"
        >
          <SubscribeModalOverlay {...props} />
        </CSSTransition>
      </React.Fragment>
    );
  };


export default SubscribeModal
