import React, {useState, useEffect} from 'react';

import * as Icons from 'react-icons/ri'

import axios from 'axios';
import { toast } from 'react-toastify';


function UploadImage({
    onFiles,
    ...props
}) {

    const [dragEnter,
        setDragEnter] = useState(false);
    
    const [file,
            setFile] = useState(null);

    const [urlFileList,
        setUrlFileList] = useState(null);

    
    const [imgs,setImgs] = useState(null);

    const [percent,setPercent] = useState(null);

    const [sent,setSent] = useState(true);


    const handleFiles = async (e) => {
        
        setFile(e.target.files[0])
       
    };

    const handleDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    };
    const handleDragOver = (e) => { 
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    };
    const handleDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(false);
    };
    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        
        setFile(e.dataTransfer.files[0])
       
    };

const UpdateImageUrls = useEffect(() =>{
        onFiles(urlFileList)
       
    },[onFiles, urlFileList])
   


    useEffect(() =>{

        const fileDisplay = () =>{
            if(file){
                
                
                if(file){
                    if (file.size >= 50000000000 ){
                        
                        toast.error(`${file.name} is too large (${handleSize(file.size)})`, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });
                    }
                    const reader = new FileReader();
                    reader.onload = async (e) =>{
                        
                        setImgs(e.target.result)
                        
                        try{
                            setSent(false)
                            const formData = new FormData();
                    
                            formData.append("file", file);
                            formData.append('upload_preset', 'myadvantage-profile');
                    
                            const options = {
                                onUploadProgress: (progressEvent) => {
                                    const {loaded, total} = progressEvent;
                                    let percent = Math.floor( loaded * 100 / total);
                    
                                    if (percent < 100){
                                        setPercent(percent)
                                    }
                                }
                    
                    
                            }
                    
                            await axios.post("https://api.cloudinary.com/v1_1/advantage-health-africa/auto/upload",formData, options).then(res =>{
                                
                                setUrlFileList( res.data.secure_url)
                                setSent(true)
                                return res.data.secure_url;
                            })
                        }catch(e){
                            console.log(e)
                        }
                        // onFiles(urlFiles)
                        
                        
                    };
                    
                    
                    reader.readAsDataURL(file)
                }


            }
        }

        fileDisplay()

        

            
    },[file, UpdateImageUrls])


    


    const handleSize = (size) =>{
        if(size <= 1000000){
            return (size/1000).toFixed(2) + 'KB';
        }
        if(size === 1000000 || size <= 1000000000){
            return (size/1000000).toFixed(2) + 'MB';
        }
        if(size === 1000000000 || size <= 1000000000000){
            return (size/1000000000).toFixed(2) + 'GB';
        }
    }

    const onClearImg = ()=>{
        
        setImgs(null)

        setUrlFileList(null)
        
    }

    

    return (
        <div className={`${props.colSpan && 'md:col-span-2 '} ${props.className}`}>
            <h2 className='mb-2 text-sm font-gilroy-bold text-link-color'>{props.title}</h2>
            <div
                
                className={`relative z-0 py-4 px-2 mb-2 gap-4 w-full rounded-lg border-2 border-dashed flex  ${imgs !== null&& 'bg-border-color'} ${dragEnter && 'bg-border-color opacity-50'}`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                accept={props.accept} >
                    {imgs === null && <div className='absolute top-0 left-0 h-full w-full text-primary-black flex flex-col justify-center items-center'>
                        <h3 className='text-sm md:text-lg opacity-80 text-center font-bold'>Drop your Images here</h3>
                        </div>}
                    {!sent && <div className='absolute top-0 left-0 h-full w-full bg-black opacity-60 text-white flex flex-col justify-center items-center z-50'>
                        <h3 className='text-sm md:text-base text-center font-bold'>Uploading Images</h3>
                        <p className='text-xs md:text-sm text-center'>Please Kindly wait for images to be uploaded</p>
                        </div>}
                    <div className='z-10 flex flex-wrap align-items'>

                        <div className='relative w-32 h-32 m-2'>
                            {imgs && <img src={imgs} alt='document' className={`w-32 h-32 object-cover `} />}
                            {
                                percent> 0 && sent === false && (
                                    <div className=" bg-gray-200 h-1 absolute top-2/3 left-2 w-10/12 rounded-full">
                                        <div className="bg-primary-purple h-1 rounded-full" style={{width: `${percent}%`}}></div>
                                    </div>
                                )
                                
                            }
                            
                            
                           {imgs &&  <Icons.RiCloseFill className="absolute top-1 right-1 text-sm text-primary-purple bg-white rounded cursor-pointer" onClick={() => onClearImg()}/>}
                        </div>
                        
                    
                    </div>
                </div>
            <label htmlFor='Dropbox' className='text-primary-purple font-gilroy-bold text-sm cursor-pointer'>
                Select file from device <span className="dash__color">{props.browse}</span>
            </label>
            <input
                id="Dropbox"
                type="file"
                className='w-1 h-1 opacity-0 '
                accept={props.accept}    
                onChange={handleFiles}/>
        </div>
    )
}

export default UploadImage;