import React, { useEffect, useState } from 'react'

import * as Icont from "react-icons/fa";
// import * as Iconr from 'react-icons/ri'

import {images} from "../assets/images/index"
// import {AuthContext} from '../context/auth-context';


// custom hooks
import {useHttpClient} from '../hooks/less-http-hook';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

//custom utils

import { useSearchParams } from 'react-router-dom';
import PlanCard from './components/PlanCard';
import TransferModal from './components/TransferModal'
import { fetchData } from '../util/store/store';

import SuccessfulPaymentModal from './components/SuccessfulPaymentModal'

// import { copyToClipBoard } from '../util/copyToClipboard';



const PaymentPage  = () => {

  //  const history = useNavigate()

    const [searchParamsData, setSearchParamsData] = useState({})

    const searchData = fetchData("userPaymentData")

    const [checkDebit, setCheckDebit] = useState(false)

    // const [checkTransfer, setCheckTransfer] = useState(false)

    const [transferModal, setTransferModal] = useState(false)

    const [successModal, setSuccessModal] = useState(false)

    const [planData, setPlanData] = useState(null)

    const { error, sendRequest} = useHttpClient();

    const [searchParams] = useSearchParams();

    // const auth = useContext(AuthContext);

    useEffect(() => {
        const fetchPlan = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/plans/list`, "GET", null);
                if(responseData){
                    // console.log(responseData)
                    // console.log(searchData?.planId.toString())
                    Object.entries(responseData.plan).forEach(item => {
                        if (item[1].id.toString() === searchParams.get('planId').toString()){
                            
                            return setPlanData(item[1])
                        } 
                        
                      })
                }
                
            } catch (err) {
                console.log(error)
            }
        };
        fetchPlan();
    }, [sendRequest, error,searchData?.planId, searchParams]);

    // useEffect(() => {
    //     setSearchData(fetchData("userPaymentData"));
  
    // }, [])

    useEffect(() => {
        setSearchParamsData({
          reference: searchParams.get('reference'),
          email: searchParams.get('email'),
          phonenumber: searchParams.get('phonenumber'),
          name: `${searchParams.get('firstname')} ${searchParams.get('lastname')}`,
          planId: searchParams.get('planId'),
          token: searchParams.get('token'),
        });
  
    }, [searchParams])

    const paymentHandler = async(response) => {
        
        try {
            console.log("i tried here")
            const responseData = 
            await sendRequest(`${process.env.REACT_APP_API_CALL}/v1/customers/verifypayments?ref=${searchParamsData.reference}`, 
            "GET", 
            null, 
            {'Content-Type': 'application/json',Authorization: 'Bearer '+searchParamsData.token});
            if(responseData){
              // auth.login(searchParams.get('token'));
              // removeData("userPaymentData")
              // history("/")
              console.log("reached here guus")
              successModalHandler()
            }
            if(!responseData){
              console.log("failed here")
              // successModalHandler()
            }
        } catch (err) {
            successModalHandler()
            console.log(error)
        }
    }

    const config = {
        public_key: process.env.REACT_APP_FLUTTERWAVE_TEST_PUBLIC_KEY,
        tx_ref: searchParamsData.reference,
        amount: planData? planData.plan_amount : 0,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: searchParamsData.email,
          phonenumber: searchParamsData.phonenumber,
          name: `${searchParamsData.name}`,
        },
        customizations: {
          title: `Myadvantage plan payment`,
          description: `Payment for subscribed plan`,
          // logo: images.logo[0],
        },
      };
    
      const handleFlutterPayment = useFlutterwave(config);


    const checkDebitHandler = () =>{
        // setCheckTransfer(false)
        setCheckDebit(!checkDebit)
        
    }

    // const checkTransferHandler = () =>{
    //     setCheckDebit(false)
    //     setCheckTransfer(!checkTransfer)
        
    // }

    const transferModalHandler = () =>{
        setTransferModal(!transferModal)
    }

    const successModalHandler = () =>{
      setSuccessModal(!successModal)
  }


  return (
    <>
    <TransferModal 
    show={transferModal} 
    onCancel={transferModalHandler} 
    firstname={searchParams.get('firstname')} 
    lastname={searchParams.get('lastname')} 
    reference={searchParams.get('reference')}
    token={searchParams.get('token')}/>

    <SuccessfulPaymentModal 
    token={searchParams.get('token')} 
    show={successModal} 
    onCancel={successModalHandler} error={error}/>

      <div className="relative min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8 background__auth">
        
        <div className=" relative max-w-5xl w-full space-y-8">
        
          <div>
            <img
              src={images.logo[0]}
              alt={`Logo`}
              className="mx-auto h-20 w-auto"
            />
            <div className='grid grid-cols-1 '>
              
              <h2 className="mt-6 mx-auto text-center text-3xl lg:text-5xl font-gilroy-extra-bold text-primary-orange-title">
                Payment method
                </h2>
              <div></div>
            </div>

            <div className="mt-8 form_login items-center ">
                <div className='flex flex-col md:flex-row justify-between items-center'>
                  <h2 className='text-primary-black font-extra text-xl mb-2 md:mb-0 lg:text-3xl'>Your payment plan</h2>
                  {/* {console.log(planData)} */}
                  <PlanCard 
                  title={planData && planData.plan_name} 
                  period={planData && planData.tenor} 
                  amount={planData && planData.plan_amount}
                  text={`This payment plan would be charged every ${planData && planData.tenor}.`} 
                  className="payment_card"/>
                
                </div>

                <div className='col-span-2 mt-12 payment_button'>
                    <p className='flex items-center text-lg mb-8'> 
                        <input type="radio" name="payment-mode" id="payment-mode-c" className="hidden" checked={checkDebit} onClick={checkDebitHandler}/>
                        <label htmlFor="payment-mode-c" className="flex items-center cursor-pointer text-xl">
                        <span className="w-8 h-8 inline-block mr-6 rounded-full border border-grey flex-no-shrink"></span>
                        <Icont.FaCreditCard className='mr-2'/> Debit card</label>
    
                    </p>

                    {checkDebit && <button 
                    className='bg-primary-black px-6 py-2 text-white ml-6 rounded-sm hover:bg-primary-purple cursor-pointer ' 
                    
                    onClick={() => {
                        handleFlutterPayment({
                          callback: (response) => {
                              paymentHandler(response)
                              closePaymentModal() // this will close the modal programmatically
                          },
                          onClose: () => {},
                        });
                      }}>
                        Pay with Flutterwave
                    </button>}


                    {/* <p className='flex items-center text-lg mt-10'> 

                        <input type="radio" name="payment-mode" id="payment-mode-t" className="hidden" checked={checkTransfer} onClick={checkTransferHandler} />
                        <label htmlFor="payment-mode-t" className="flex items-center cursor-pointer text-xl">
                        <span className="w-8 h-8 inline-block mr-6   rounded-full border border-grey flex-no-shrink"></span>
                        <Icont.FaCreditCard className='mr-2'/> Transfer</label>
                    </p>
                    {
                        checkTransfer && <div className='mt-12 ml-6'>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4'>
                            <h4 className=''>Bank Name</h4>
                            <h4 className='text-primary-purple font-gilroy-bold text-base'>VFD Micro Finance Bank</h4>
                        </div>

                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4'>
                            <h4 className='flex items-center'>Account Number 
                            <span className='inline-block ml-4'>
                                <Iconr.RiFileCopyLine className='text-primary-purple text-lg cursor-pointer' 
                                onClick={(e) => {
                                    copyToClipBoard(1008872342)
                                }}/></span>
                            </h4>
                            <h4 className='text-primary-purple font-gilroy-bold text-base'>1008872342</h4>
                        </div>

                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4'>
                            <h4 className=''>Bank Name</h4>
                            <h4 className='text-primary-purple font-gilroy-bold text-base'>ADVANTAGE NETWORK PAN AFRICAN INVESTMENTS LIMITED</h4>
                        </div>

                        <button className='bg-primary-purple px-4 py-2 text-white rounded' onClick={transferModalHandler}>
                         {`I have paid ₦${planData.plan_amount}.00`}
                        </button>

                    </div>
                    } */}

                    <p className='text-primary-purple text-sm mt-16 flex items-center justify-center'>Contact Finance@myAdvantage.com for inquiries</p>
                </div>
            </div>
            
            



          </div>  
          
        </div>
      </div>
    </>
  )
}


export default PaymentPage 