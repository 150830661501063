import React, { useContext } from 'react'

// import * as Icons from "react-icons/io";

import { images } from '../../assets/images';

import '../auth.css'

import {useNavigate} from 'react-router-dom';

import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Backdrop from '../../UI_Elements/Backdrop/Backdrop';
import { AuthContext } from '../../context/auth-context';
import { removeData } from '../../util/store/store';


function RedirectPaymentModalOverlay(props) {
    const history = useNavigate();
    const auth = useContext(AuthContext)

  const submitHandler = (event) =>{
    event.preventDefault();
    auth.login(props.token);
    removeData("userPaymentData")
    history("/")   
  }

    const content = (
        <div className='p-8 md:p-24 modal'>
            {props.error?.length > 1 ? (<div className={`flex flex-col justify-center items-center`}>
            <h3 className='text-primary-black text-lg font-gilroy-bold mt-2 mb-8'>Error Occurred Verifying payment</h3>

                <p className='text-link-color text-center'>
                    {`You’ll receive an email notification to sign in to your 
                    dashboard once your payment is confirmed. if you have been debited and yet to receive an email, 
                    please send us an email with a screenshot of your transaction, thank you`}
                </p>

                <button 
                className={`bg-primary-black mt-4 px-6 py-2 text-white ml-6 rounded-sm 
                hover:bg-primary-purple cursor-pointer`} 
                onClick={() => {
                    props.onCancel()
                    history("/")
                }}
                >
                    Close
                </button>
            </div>) : (
                <div className={`flex flex-col justify-center items-center`}>
                <img src={images.successMarker[0]} alt={images.successMarker[1]} className="object-contain"/>
                <h3 className='text-primary-black text-lg font-gilroy-bold mt-2 mb-8'>Payment successful</h3>

                <p className='text-link-color text-center'>
                    {`You’ll receive an email notification to sign in to your 
                    dashboard once your payment is confirmed.`}
                </p>

                <button 
                className={`bg-primary-black px-6 py-2 text-white ml-6 rounded-sm 
                hover:bg-primary-purple cursor-pointer`} 
                onClick={(e) =>{
                    console.log(props.error)
                    submitHandler(e)
                }}
                >
                    Continue
                </button>
            </div>
            )}
        </div>
    )

    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const RedirectPaymentModal = props => {
    return (
      <React.Fragment>
        {props.show && <Backdrop onClick={props.onCancel} />}
        <CSSTransition
          in={props.show}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames="modal"
        >
          <RedirectPaymentModalOverlay {...props} />
        </CSSTransition>
      </React.Fragment>
    );
  };


export default RedirectPaymentModal
